import React, { useState, useEffect } from "react";
import { 
	Avatar, 
	List, 
	Space, 
	Skeleton, 
	Button, 
	Drawer, 
	Select, 
	message, 
	Popconfirm,
	Transfer,
	Modal,
	Input,
} from "antd";
import { 
	QuestionCircleOutlined,
	EditOutlined,
} from "@ant-design/icons";
import { get_users, edit_user } from "../../services/users";
import { get_companies } from "../../services/companies";
import { get_databases } from "../../services/databases";
import "../../hover.css";
import PropTypes from "prop-types";

const no_selected_user = {
	name: "",
	email: "",
	role: "",
	assigned_companies: [],
	assigned_databases: [],
};

const empty_credentials = {
	database_user: "",
	key: ""
};

const Users = ({token, setUser, refreshCompanies}) => {
	const [loading, setLoading] = useState(true);
	const [loadingSubmit, setLoadingSubmit] = useState(false);	
	const [users, setUsers] = useState([]);
	const [open, setOpen] = useState(false);
	const [openEditCompanies, setOpenEditCompanies] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const placement = "right";
	const [selected, setSelected] = useState(no_selected_user);
	const [selectedKeysCompanies, setSelectedKeysCompanies] = useState([]);
	const [selectedKeysDatabases, setSelectedKeysDatabases] = useState([]);
	const [clientCompanies, setClientCompanies] = useState([]);
	const [clientDatabases, setClientDatabases] = useState([]);
	const [originalDatabases, setOriginalDatabases] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [hoveredItem, setHoveredItem] = useState(null);
	const [detectedErrors, setDetectedErrors] = useState([]);
	const [selectedCredential, setSelectedCredential] = useState("");
	const [credentials, setCredentials] = useState([]);
	const [userDatabases, setUserDatabases] = useState([]);
	const [auxiliarInput, setAuxiliarInput] = useState(empty_credentials);

	const joinDatabasesInformation = (user_databases) => {
		const databasesFiltered = user_databases.map((secondItem) => {
			const itemsMatched = clientDatabases.find(
				(firstItem) => firstItem.id === secondItem
			);

			if(itemsMatched) {
				return itemsMatched;
			}
		});
		return databasesFiltered; // array
	};

	const companiesAllowedByDB = (data_assigned_databases) => {
		const data_to_filter = (data_assigned_databases === undefined) ? selected.assigned_databases : data_assigned_databases;

		const companiesFiltered = data_to_filter.flatMap((item) => {
			const itemsMatched = clientDatabases.find(
				(itemToMatch) => itemToMatch.id === item
			);
			
			if(itemsMatched){
				const founded_company = itemsMatched.assigned_companies.flatMap((company) => {
					const found = clientCompanies.find(
						(client_company) => client_company.id === company
					);

					return found ? { ...found } : null;
				});

				return founded_company;
			}
		});

		setOriginalDatabases(companiesFiltered.filter((item) => item !== null));
	};
	
	const prepareUserDatabases = (item) => {		
		setUserDatabases(joinDatabasesInformation(item.assigned_databases));
	};
	
	const handleChangeAssignedCompanies = (newTargetKeys) => {
		setSelected({...selected, assigned_companies: newTargetKeys});
	};

	const handleSelectChangeCompanies = (sourceSelectedKeys, targetSelectedKeys) => {
		setSelectedKeysCompanies([...sourceSelectedKeys, ...targetSelectedKeys]);
	};

	const handleChangeAssignedDataBases = (newTargetKeys) => {
		const db_refactored = newTargetKeys.map((db_id) => {
			const itemMatch = clientDatabases.find(
				(item) => item.id === db_id
			);

			return itemMatch;
		});

		setUserDatabases(db_refactored);
		setSelected({
			...selected,
			assigned_databases: db_refactored.map((db)=> db.id)
		});
	};

	const handleSelectChangeDatabases = (sourceSelectedKeys, targetSelectedKeys) => {
		setSelectedKeysDatabases([...sourceSelectedKeys, ...targetSelectedKeys]);
	};

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
		setSelected(no_selected_user);
		setSelectedCredential("");
		setCredentials([]);
		setSelectedKeysCompanies([]);
		setSelectedKeysDatabases([]);
		setUserDatabases([]);
		setOriginalDatabases([]);
		setDetectedErrors([]);
	};

	const handleChangeRole = (value) => {
		setSelected({...selected, role : value});
	};

	const submitChanges = async () => {
		
		setLoadingSubmit(true);
		try {
			setLoading(true);
			setDetectedErrors([]);

			const user_notification = {
				token,
				email: selected.email,
				role: selected.role,
				assigned_companies: selected.assigned_companies,
				assigned_databases: selected.assigned_databases,
				credentials
			};

			const res_edit = await edit_user(token, user_notification);
			if (res_edit) {
				if (res_edit.status === 200) {
					if(res_edit.data.status === true){
						message.success(res_edit.data.message);
					}
					else{
						message.warning(res_edit.data.message);
						setDetectedErrors(res_edit.data.errors);
					}
				} else {
					message.error(res_edit.data);
				}

				const res_users = await get_users(token);
				if (res_users) {
					setUsers(res_users);		
					updateUserInformation(res_users, (res_edit.status === 200 && open && res_users));
				}

				// Assigned Companies ready and done
				if(res_edit.status === 200 && openEditCompanies){
					setOpenEditCompanies(false);
					onClose();
				}
			}
			setLoading(false);
			setLoadingSubmit(false);
		} catch (error) {
			console.error(error);
			setLoadingSubmit(false);
		}
	};

	const updateUserInformation = async (res_users, open_edit_companies) => {
		const updateUser = res_users.find(
			(searchUser) => searchUser.email === selected.email
		);

		if(updateUser === null || updateUser === undefined) return;
		
		setSelected(updateUser);
		setUser(updateUser);
		refreshCompanies();
		companiesAllowedByDB(updateUser.assigned_databases);

		setOpenEditCompanies(false);

		if(!open_edit_companies) return;

		if(updateUser.assigned_databases.length === 0){
			onClose();
			return;
		}

		setOpenEditCompanies(true);
	};

	const deleteUser = async () => {
		try {
			setLoading(true);
			var res = await edit_user(token, "delete", selected);
			if (res) {
				if (res.status == 200) {
					message.success(res.data);
					onClose(); 
				} else {
					message.error(res.data);
				}
				res = await get_users(token);
				if (res) {
					setUsers(res);
					setLoading(false);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const updateCredentials = (credentials, selectedCredential, auxiliarInput) =>  {
		// Verificar si la database_id ya existe en las credenciales
		const existingIndex = credentials.findIndex(credential => credential.database_id === selectedCredential);
	
		if (existingIndex !== -1) {
			// Si la database_id ya existe, actualizamos esa entrada
			credentials[existingIndex] = {
				"database_id": selectedCredential,
				"database_user": auxiliarInput.database_user,
				"key": auxiliarInput.key
			};
		} else {
			// Si la database_id no existe, agregamos una nueva entrada
			credentials.push({
				"database_id": selectedCredential,
				"database_user": auxiliarInput.database_user,
				"key": auxiliarInput.key
			});
		}
	
		// Aquí puedes usar tu función para actualizar el estado o lo que necesites hacer con las credenciales actualizadas
		setCredentials(credentials);
	};

	const processEdit = () => {
		if(auxiliarInput.database_user.trim() === "" || auxiliarInput.key.trim() === ""){
			message.warning("Porfavor llene la información de todos los inputs.");
			return;
		}

		updateCredentials(credentials, selectedCredential, auxiliarInput);

		setSelectedCredential("");
		setAuxiliarInput(empty_credentials);
		setOpenEditModal(false);
		message.info("Debe seleccionar Enviar para guardar sus cambios");
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await get_users(token);
				if (res) {
					setUsers(res);

					const res_companies = await get_companies(token,"is_associated");
					if (res_companies) {
						setClientCompanies(res_companies.map((company) => {
							return {
								name: company.name,
								id: company.id,
								key: company.id,
								tid: company.tid,
							};
						}));
					}

					const res_databases = await get_databases(token);
					if(res_databases){
						setClientDatabases(res_databases.map((database) => {
							return {
								name: database.name,
								key: database.id,								
								active: database.active,
								system: database.system,
								version: database.version,
								id: database.id,
								assigned_companies: database.assigned_companies,
								database_user: "",
								genereted_key: "",
							};
						}));
					}
				}
				
				setLoading(false);
			} catch (error) {
				console.error(error);
			}			
		};    
    
		fetchData();

		function calculateListSize() {
			const windowHeight = window.innerHeight;
			const elementHeight = 65;
			const maxListHeight = windowHeight - 240;
            
			const numElements = Math.floor(maxListHeight / elementHeight);
			if (numElements == 0) {
				setPageSize(1);
				return;
			}
			setPageSize(numElements);
		}

		window.addEventListener("resize", calculateListSize);

		calculateListSize();
        
		return () => {
			window.removeEventListener("resize", calculateListSize);
		};
	}, []);
    
	return (
		<>
			<Skeleton avatar loading={loading} active>
				<List
					pagination={{
						position:"top",
						align:"end",
						defaultPageSize:"7",
						pageSize:pageSize
					}}
					dataSource={users}
					renderItem={(item, index) => (
						<List.Item
							key={index}
							onMouseEnter={() => setHoveredItem(index)}
							onMouseLeave={() => setHoveredItem(null)}
							className={hoveredItem === index ? "hovered-item" : ""}
							onClick={() => {showDrawer(); setSelected(item); prepareUserDatabases(item);}}
							actions={[<a key="edit"></a>]}
						>
							<Skeleton avatar title={false} loading={loading} active>
								<List.Item.Meta
									avatar={
										<Avatar src={item.picture} />
									}
									title={item.name}
									description={item.email}
								/>
								<div>{item.role + "\t"}</div>
							</Skeleton>                    
						</List.Item>
					)}
				/>
			</Skeleton>
        
			<Skeleton avatar active loading={loading} />
			<Skeleton avatar active loading={loading} />
			<Skeleton avatar active loading={loading} />
			<Skeleton avatar active loading={loading} />

			<Drawer
				title="Editar Usuario"
				placement={placement}
				width={800}
				onClose={onClose}
				open={open}
				extra={
					<Space>
						<Button 
							onClick={() => {onClose(); setSelected(no_selected_user);}}
							loading={loadingSubmit}
						>
							Cancelar
						</Button>
						<Popconfirm
							title="Eliminar Usuario"
							description="Estas seguro que quieres eliminar este usuario?"
							onConfirm={() => {deleteUser();}}
							icon={<QuestionCircleOutlined style={{ color: "red" }} />}
						>
							<Button type="primary" danger loading={loadingSubmit}>
                Eliminar
							</Button>
						</Popconfirm>
						<Button type="primary" loading={loadingSubmit} onClick={() => {submitChanges();}}>
              Enviar
						</Button>
					</Space>
				}
			>					
				<div>
					{detectedErrors.length !== 0 && <h3 style={{color: "red"}}>Errores detectados y que corregir:</h3>}
					{detectedErrors.length !== 0 && detectedErrors.map((message_error, index) => (
						<p key={index} style={{color: "red"}}>&#x2022; {message_error}</p>
					))}
				</div>
				<div><h3>Nombre: </h3><p>{selected.name}</p></div>
				<div><h3>Correo: </h3><p>{selected.email}</p></div>
				<div><h3>Rol: </h3>
					<Select
						labelInValue
						value={
							{
								value: selected.role,
								label: selected.role,
							}
						}
						style={{
							width: 120,
						}}
						onChange={(value) => {handleChangeRole(value.value);}}
						options={[
							{
								value: "admin",
								label: "admin",
							},
							{
								value: "accountant",
								label: "accountant",
							},
							{
								value: "undefined",
								label: "undefined",
							},
						]}
					/></div>
				<div><h3>Bases de datos:</h3></div>
				<Transfer
					dataSource={clientDatabases}
					listStyle={{
						width: 350,
						height: 300,
					}}
					titles={["Bases de Datos Disponibles", "Bases de Datos Asignados"]}
					targetKeys={userDatabases.map((db => db.id))}
					selectedKeys={selectedKeysDatabases}
					onChange={handleChangeAssignedDataBases}
					onSelectChange={handleSelectChangeDatabases}
					render={(item) => { return (`${item.name} - ${item.system} v${item.version}`); }}
					style={{
						marginBottom: 16,
						marginTop:"10px"
					}}
				/>
				{(userDatabases.length !== 0 &&
					<div>
						<h3>Credenciales:</h3>
						<div style={{
							backgroundColor: "#f5f5f5",
							width: "95%",
							height: "150px",
							overflowX: "hidden",
							overflowY: "auto",
							textAlign: "center",
							padding: "20px",
						}}>
							{/* O talvez esto */}
							{userDatabases.map((item, index) => {
								return (
									<>
										<p key={index}>{`${item.name} - ${item.system} v${item.version}. Estado: `}</p>
										<Space>
											<Button
												type="default"
												icon={<EditOutlined />}
												size="small"
												onClick={() => {
													setSelectedCredential(item.id);
													/* setAuxiliarInput({
														database_user: item.database_user,
														key: item.genereted_key
													}); */
													setOpenEditModal(true);}
												}
											/>
										</Space>
									</>
								);
							})}
						</div>
					</div>					
				)}
				<br/>
				<div>
					<Button
						disabled={(selected.assigned_databases.length === 0)}
						onClick={() => {
							setOpen(false);
							setOpenEditCompanies(true);
							companiesAllowedByDB();
						}}
					>						
						{`${(selected.assigned_databases.length === 0) ? 
							"No hay bases de datos asignadas para asignar compañias" : 
							"Asignar Compañias con las iniciales bases de datos asignadas"}`
						}
					</Button>
				</div>
			</Drawer>
			<Drawer
				title="Editar Compañias de Usuario"
				placement={placement}
				width={800}
				onClose={() => {
					setOpenEditCompanies(false);
				}}
				open={openEditCompanies}
				extra={
					<Space>
						<Button onClick={() => {
							setOpen(true);
							setOpenEditCompanies(false);
						}}>Regresar a Editar Usuario</Button>
						<Button type="primary" loading={loadingSubmit} onClick={() => {submitChanges();}}>
              Enviar
						</Button>
					</Space>
				}
			>				
				<div><h3>Compañias de las bases de datos asignadas del usuario:</h3></div>
				<Transfer
					dataSource={originalDatabases}
					listStyle={{
						width: 350,
						height: 300,
					}}
					showSearch
					titles={["Clientes Disponibles", "Clientes Asignados"]}
					targetKeys={selected.assigned_companies}
					selectedKeys={selectedKeysCompanies}
					onChange={handleChangeAssignedCompanies}
					onSelectChange={handleSelectChangeCompanies}
					render={
						(item) => item.name
					}
					style={{
						marginBottom: 16,
						marginTop:"10px"
					}}
				/>
				<div><h3>Bases de datos Asignadas del Usuario:</h3></div>
				<div>
					{joinDatabasesInformation(selected.assigned_databases).map((item, index) => (
						<p key={index}>{`${item.name} - ${item.system} v${item.version}`}</p>
					))}
				</div>
			</Drawer>
			<Modal
				title={"Editar Credenciales"}
				open={openEditModal}
				onOk={processEdit} 
				onCancel={() => {setOpenEditModal(false); setAuxiliarInput(empty_credentials); setSelectedCredential("");}}
			>
				<h3>Correo:</h3>
				<Input
					rows={1}
					showCount
					status={(auxiliarInput.database_user.trim() === "") ? "error" : ""}
					value={auxiliarInput.database_user}
					onChange={(event) => setAuxiliarInput({...auxiliarInput, database_user: event.target.value})}
				/>
				<h3>Key:</h3>
				<Input
					rows={1}
					showCount
					status={(auxiliarInput.key.trim() === "") ? "error" : ""}
					value={auxiliarInput.key}
					onChange={(event) => setAuxiliarInput({...auxiliarInput, key: event.target.value})}
				/>
			</Modal>
		</>
        
	);
};

Users.propTypes = {
	token: PropTypes.string.isRequired,
	setUser: PropTypes.func.isRequired,
	refreshCompanies: PropTypes.func.isRequired,
};

export default Users;
