import React, { useEffect, useState, useRef } from "react";
import {
	List,
	Skeleton,
	message,
	Button,
	Drawer,
	Space,
	Popconfirm,
	Collapse,
	Select,
	DatePicker,
	Input,
	Checkbox,
	Row,
	Col,
} from "antd";
import { 
	CheckOutlined, 
	CloseCircleOutlined, 
	QuestionCircleOutlined ,
	ReloadOutlined,
	RightOutlined,
	LeftOutlined,
} from "@ant-design/icons";
import { get_errors_of_error_pool, edit_error_in_error_pool } from "../../services/error_pool";
import dayjs from "dayjs";
import "../../hover.css";
import PropTypes from "prop-types";

const { RangePicker } = DatePicker;
const { Search } = Input;
const { Panel } = Collapse;

const empty_report_error = {
	id: "",
	name_process: "",
	error_verified: true,
	date: "",
	verified_by: "",
	date_of_verification: "",
	type: "",
	errors_types_detected: [],
	documents_id: [],
	specs: {}
};

const default_parameters = {
	name_process: null,
	error_verified: 0,
	type: null,
};

const rangePresets = [
	{
		label: "Last 7 Days",
		value: [dayjs().add(-7, "d"), dayjs()],
	},
	{
		label: "Last 14 Days",
		value: [dayjs().add(-14, "d"), dayjs()],
	},
	{
		label: "Last 30 Days",
		value: [dayjs().add(-30, "d"), dayjs()],
	},
	{
		label: "Last 90 Days",
		value: [dayjs().add(-90, "d"), dayjs()],
	},
];

const massive_elements_limit = 50;

const ErrorPool = (props) => {
	const [errorsReports, setErrorsReports] = useState([]);
	const [backupErrorsReports, setBackupErrorsReports] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState(empty_report_error);
	const [openEdit, setOpenEdit] = useState(false);
	const [seeErrorsReports, setSeeErrorsReports] = useState(false);
	const [hoveredItem, setHoveredItem] = useState(null);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	
	const [pageSize, setPageSize] = useState(10);
	const [filterOptions, setFilterOptions] = useState({
		"types_errors":[],
		"process_errors":[],
	});
	const [selectedOptions, setSelectedOptions] = useState(default_parameters);
	const showMessageRef = useRef(false);

	const [massiveCheckedList, setMassiveCheckedList] = useState([]);
	const checkAll = errorsReports.length === massiveCheckedList.length;
	const indeterminate = massiveCheckedList.length > 0 && massiveCheckedList.length < errorsReports.length;

	const fetchData = async () => {		
		setLoading(true);
		setSeeErrorsReports(false);
		setSelectedOptions(default_parameters);
		try {			
			const res_get_errors = await get_errors_of_error_pool(props.token);
			if(res_get_errors){
				setMassiveCheckedList([]);
				setErrorsReports(res_get_errors.errors);
				setBackupErrorsReports(res_get_errors.errors);
				loadDataAndOrUpdateParameters("error_verified", 0, res_get_errors.errors);
				
				const counter = res_get_errors.amount_errors;
				const msg = (counter > 0) ? `¡Data Cargada! Tienes que corregir ${counter} reportes de errores` : "Data Cargada";

				if (!showMessageRef.current){
					message.success(msg);
					showMessageRef.current = true;
				}
												
				setFilterOptions({
					"types_errors": uniqueElementsArray(res_get_errors, "type"),
					"process_errors": uniqueElementsArray(res_get_errors, "name_process"),
				});

				setSeeErrorsReports((counter > 0));			
			}
		} catch (error) {
			console.error(error.response.data);
			message.error(error.response.data);
		}
		
		setLoading(false);
		window.addEventListener("resize", calculateListSize);
		calculateListSize();
		return () => window.removeEventListener("resize", calculateListSize);
	};
	
	const updateStatusError = async () => {
		setLoadingSubmit(true);
		setLoading(true);
		try {
			const data_to_send = (massiveCheckedList.length > 1 && selectedOptions.error_verified === 0) 
				? massiveCheckedList : [selected.id];
			const res_edit_error = await edit_error_in_error_pool(props.token, data_to_send, props.user.email);
			if(res_edit_error){
				fetchData();				
				message.success(res_edit_error.data);
				onClose();
			}
		} catch (error) {
			console.error(error.response.data);
			message.error(error.response.data);
		}
		setLoadingSubmit(false);
		setLoading(false);
	};

	const uniqueElementsArray = (data, attribute) => {
		const uniqueTypesErrors = new Set();
		data.errors.forEach(obj => uniqueTypesErrors.add(obj[attribute]));
		const uniqueTypesErrorsArray = [...uniqueTypesErrors];

		const arrayUnique = uniqueTypesErrorsArray.map(type => ({
			value: type,
			label: type,
		}));
								
		return [{value:null, label:"Todos"}, ...arrayUnique];
	};

	const calculateListSize = () => {
		const windowHeight = window.innerHeight;
		const elementHeight = 65;
		const maxListHeight = windowHeight - 390;

		setPageSize(Math.max(1, Math.floor(maxListHeight / elementHeight)));
	};

	const loadDataAndOrUpdateParameters = (attribute, selectedValue, data_to_load) => {
		const parameters = (attribute === undefined || selectedValue === undefined) ? 
			selectedOptions : {...selectedOptions, [attribute]: selectedValue};

		const data = (data_to_load) ? data_to_load : backupErrorsReports;
		
		const filteredData = data.filter(item => {
			return Object.keys(parameters).every(key => {				
				if (key === "name_process" && parameters[key] !== null) {
					return item.name_process === parameters[key];
				}
				if (key === "type" && parameters[key] !== null) {
					return item.type === parameters[key];
				}
				if (key === "error_verified" && parameters[key] !== -1) {															
					return item.error_verified === (parameters[key] === 1);
				}
				
				return true;
			});
		});

		setErrorsReports(filteredData);	
		setSelectedOptions(parameters);
		return filteredData;
	};
		
	const showEdit = () => {
		setOpenEdit(true);		
	};

	const onClose = () => {
		setOpenEdit(false);
		setSelected(empty_report_error);
		setHoveredItem(null);
	};

	const reduceText = (text) => {
		if (text.trim() === "") {
			return "---";
		}
		if (text.length > 40) {
			return text.substring(0, 40) + "...";
		}
		return text;
	};

	const onChangeSearch = (value) => {
		const text = value.target.value;

		const matchesText = (str) => {
			if (typeof str === "string") {
				return str.toLowerCase().includes(text.toLowerCase());
			}
			return false;
		};

		const checkObjectForMatch = (obj) => {
			for (const key in obj) {
				if (matchesText(obj[key])) {
					return true;
				}

				if (typeof obj[key] === "object" && obj[key] !== null) {
					if (checkObjectForMatch(obj[key])) {
						return true;
					}
				}
			}
			return false;
		};

		if(text.trim() === "") {
			setErrorsReports(loadDataAndOrUpdateParameters());
			return;
		}

		const result = loadDataAndOrUpdateParameters().filter((invoice) =>
			checkObjectForMatch(invoice)
		);
		setErrorsReports(result);
	};

	const onRangeChange = (dateRange) => {
		const data = loadDataAndOrUpdateParameters();
		
		if (!dateRange || dateRange.length !== 2) {			
			return data;
		}

		const [startDate, endDate] = dateRange;

		const filteredData = data.filter(
			(item) =>
				new Date(item.date) >= startDate.startOf("day") &&
				new Date(item.date) <= endDate.endOf("day")
		);

		setErrorsReports(filteredData);
	};

	const chargeNext = () => {
		if(hoveredItem === null) return;
		
		const number = hoveredItem + 1;
		setHoveredItem(number % errorsReports.length);
		setSelected(errorsReports[number % errorsReports.length]);
	};

	const chargePrevious = () => {
		if(hoveredItem === null) return;

		const number = hoveredItem - 1;
		if(number < 0){
			setHoveredItem(errorsReports.length - 1);
			setSelected(errorsReports[errorsReports.length - 1]);
			return;
		}
		setHoveredItem(number);
		setSelected(errorsReports[number]);
	};

	const onChangeElementSelected = (e, invoice_id) => {		
		if(massiveCheckedList.length > massive_elements_limit && e.target.checked){ // Check the limit
			message.warning(`El límite de facturas a mandar de forma masiva es de ${massive_elements_limit}`);
			return;
		}
		
		if(e.target.checked){ // Add Element
			setMassiveCheckedList([...massiveCheckedList, invoice_id]);
			return;
		}
		
		setMassiveCheckedList( // Remove id invoice
			massiveCheckedList.filter((checkList) => {
				return checkList !== invoice_id;
			})
		);		
	};	

	const onChangeAllSelect = (e) => {
		const limitedInvoices = errorsReports.slice(0, massive_elements_limit);
		setMassiveCheckedList(e.target.checked ? limitedInvoices.map((invoice_id) => invoice_id.id) : []);
	};

	useEffect(() => {		
		fetchData();		
	}, []);

	const InformationComponent = ({title, attribute}) => {
		return (
			<div>
				<h3 style={{ color: "#808080" }}>{title}</h3>
				<p>{attribute}</p>
			</div>
		);
	};

	return (
		<>
			<Skeleton avatar loading={loading} active>
				{(!seeErrorsReports) && <>
					<CheckOutlined style={{ fontSize: "256px", paddingTop: "10%" }} />
					<h1>No se encontraron errores</h1>
					<Button
						onClick={() => setSeeErrorsReports(true)}
						disabled={(backupErrorsReports.length === 0)}
					>
						{(backupErrorsReports.length === 0) ? "No hay reportes de errores en error pool" : "Ver Historial"}
					</Button>
				</>}
				{(seeErrorsReports) && <>
					<Select
						showSearch
						placeholder="Tipo de Proceso ejecutado"
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						style={{
							marginLeft:"10px",
							fontSize: "30px",
						}}
						options={filterOptions.process_errors}
						onChange={(value) => {
							loadDataAndOrUpdateParameters("name_process", value);
						}}
						value={selectedOptions.name_process}
					/>
					<Select
						showSearch
						placeholder="Tipo de Error"
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						style={{
							marginLeft:"10px",
							fontSize: "30px",
						}}
						options={filterOptions.types_errors}
						onChange={(value) => {
							loadDataAndOrUpdateParameters("type", value);
						}}
						value={selectedOptions.type}
					/>
					<Select
						showSearch
						placeholder="Estados de los reportes"
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						style={{
							marginLeft:"10px",
							fontSize: "30px",
						}}
						options={[
							{
								value:0,
								label:"Pendientes"
							},							
							{
								value:1,
								label:"Ya corregidos"
							},
							{
								value:-1,
								label:"Todos"
							},
						]}
						onChange={(value) => {
							loadDataAndOrUpdateParameters("error_verified", value);
						}}
						value={selectedOptions.error_verified}
					/>
					<RangePicker
						presets={rangePresets}
						style={{
							marginLeft: "10px",
							marginTop: "10px",
						}}
						onChange={onRangeChange}
					/>
					<Button
						type="primary"
						icon={<ReloadOutlined />}
						style={{
							marginLeft: "10px",
							marginTop: "10px",
						}}
						onClick={() => {showMessageRef.current = false; fetchData();}}
					>
						Refresh
					</Button>
					<Search
						placeholder="Buscar"
						style={{
							marginTop: "10px",
							marginBottom: "10px",
						}}
						onChange={onChangeSearch}
						enterButton
					/>
					{selectedOptions.error_verified === 0 && 
						<Row>
							<Col span={8}>
								<Checkbox 
									indeterminate={indeterminate} 
									checked={checkAll} 
									onChange={onChangeAllSelect}
									style={{marginTop: "13px"}}
								>
									Seleccionar Todo
								</Checkbox>
							</Col>
							<Col span={8}>
								<p>{`${massiveCheckedList.length} de ${errorsReports.length} errores seleccionados`}</p>
							</Col>
							<Col span={8}>
								<Button 
									disabled={(massiveCheckedList.length < 2)} 
									type="primary"
									style={{marginTop: "5px"}}
									onClick={updateStatusError}
									loading={loadingSubmit}
									ghost
								>
									Corrección Masiva
								</Button>								
								
							</Col>
						</Row>
					}
					<List
						pagination={{
							position:"top",
							align:"end",
							pageSize: pageSize,
							showSizeChanger: false,
							disabled: false,
						}}
						dataSource={errorsReports}
						renderItem={(item, index) => (
							<Row>
								<Col flex="30px">
									<Checkbox
										disabled={(selectedOptions.error_verified !== 0)}
										checked={((massiveCheckedList.find((checked) => checked === item.id)) === undefined) ? false : true}
										onChange={(e) => onChangeElementSelected(e, item.id)} 
										style={{marginTop: "20px", marginRight: "20px"}}
									/>
								</Col>
								<Col flex="auto">
									<List.Item
										key={index}
										onMouseEnter={() => setHoveredItem(index)}
										onMouseLeave={() => {
											if(selected === empty_report_error){
												setHoveredItem(null);
											}
										}}
										className={hoveredItem === index ? "hovered-item" : ""}
										onClick={() => {
											setSelected(item);
											showEdit();
											setHoveredItem(index);
										}}
										actions={[<a key="edit"></a>]}
									>
										<Skeleton avatar title={false} loading={loading} active>
											<List.Item.Meta
												title={reduceText(item.name_process)}
												description={reduceText(item.type)}
											/>										
											{(item.error_verified) ? (<CheckOutlined style={{ color: "green" }}/>) : (<CloseCircleOutlined style={{ color: "red" }}/>)}
										</Skeleton>
									</List.Item>
								</Col>
							</Row>
						)}
					/>
				</>}
			</Skeleton>
			<Drawer
				title="Corregir Error"
				placement="right"
				width={800}
				onClose={onClose}
				open={openEdit}
				extra={
					<Space>
						<>
							{selectedOptions.error_verified === 0 && <Checkbox 
								disabled={selected.error_verified}
								checked={((massiveCheckedList.find((checked) => checked === selected.id)) === undefined) ? false : true}
								onChange={(e) => onChangeElementSelected(e, selected.id)} 
							>Marcar para envio masivo</Checkbox>}
						</>
						<Button
							type="primary"
							icon={<LeftOutlined />}
							size="large"
							onClick={() => {
								chargePrevious();
							}}
						/>
						<Button
							type="primary"
							icon={<RightOutlined />}
							size="large"
							onClick={() => {
								chargeNext();
							}}
						/>
						<Popconfirm
							title="Error corregido"
							description="¿Estas seguro que ya revisaste que los errores esten corregidos?"
							onConfirm={() => {showMessageRef.current = false; updateStatusError();}}
							icon={<QuestionCircleOutlined style={{ color: "red" }} />}
						>
							{(selectedOptions.error_verified !== 0) && 
							<Button type="primary" disabled={(selected.error_verified)} loading={loadingSubmit}>			
								{(!selected.error_verified) ? "Marcar como corregido" : "Ya esta Corregido"}					
							</Button>
							}
							{(massiveCheckedList.length <= 1 && selectedOptions.error_verified === 0) && 
							<Button type="primary" disabled={(selected.error_verified)} loading={loadingSubmit}>			
								{(!selected.error_verified) ? "Marcar como corregido" : "Ya esta Corregido"}					
							</Button>
							}
							{(selectedOptions.error_verified === 0 && massiveCheckedList.length > 1) && 
							<Button type="primary" disabled={(selected.error_verified)} loading={loadingSubmit}>			
								Realizar Corrección Masiva
							</Button>}							
						</Popconfirm>
					</Space>
				}
			>
				<InformationComponent title={"Nombre Procesos: "} attribute={selected.name_process} />
				<InformationComponent title={"Tipo de Error Detectado: "} attribute={selected.type} />
				<InformationComponent title={"Fecha de detección del error: "} attribute={selected.date} />
				<InformationComponent title={"Estado: "} attribute={(selected.error_verified) ? "Ya Corregido" : "Sin Corregir"} />
				{(selected.error_verified) && <InformationComponent title={"Verificado por: "} attribute={selected.verified_by}/>}
				{(selected.error_verified) && <InformationComponent title={"Fecha del Error ya Corregido: "} attribute={selected.date_of_verification}/>}
				<br></br>
				<Collapse accordion>
					<Panel header="Tipos de errores detectados">
						{selected.errors_types_detected?.map((item, index) => (
							<p key={index}>{`${item}`}</p>
						))}
					</Panel>						
				</Collapse>				
				{(selected.documents_id.length !== 0) && <>
					<br></br>
					<h3 style={{ color: "#808080" }}>{"Documentos con errores:"}</h3>
					<Collapse accordion>
						{selected.documents_id.map((item, index) => (
							(typeof item === "string" || typeof item === "number") && (
								<Panel key={index} header={item} showArrow={false} disabled={true}/>
							)
							||
							(Array.isArray(item) && (
								<Panel key={index} header={(item?.error_message?.trim() === "") ? "Error sin nombre" : item?.error_message}>
									<InformationComponent title={"ID del Documento: "} attribute={(item?.document_id?.trim() === "") ? "Documento sin ID" : item?.document_id}/>
									<InformationComponent title={"Estado: "} attribute={(item?.state?.trim() === "") ? "Sin Estado" : item?.state}/>
									{(item?.errors_types_detected?.length !== 0) && (
										<React.Fragment>
											<h3 style={{ color: "#808080" }}>{"Errores de los documentos:"}</h3>
											{item?.errors_types_detected?.map((item2, index2) => (
												<p key={index2}>{`${item2}`}</p>
											))}
										</React.Fragment>
									)}
									{(Object.keys(item?.data).length !== 0) && (
										<React.Fragment>
											<h3 style={{ color: "#808080" }}>{"Datos:"}</h3>
											{Object.entries(item?.data).map(([key, value], index) => (
												<p key={index}>{`${key} : ${(value?.trim() === "") ? "N/A" : value}`}</p>
											))}
										</React.Fragment>
									)}
								</Panel>
							))
						))}
					</Collapse>
				</>}
				<br></br>
				<br></br>
				{(Object.keys(selected.specs).length !== 0) && <Collapse accordion>
					<Panel header="Specs (Especificaciones)">
						{Object.entries(selected.specs).map(([key, value], index) => (
							<p key={index}>{`${key} : ${value}`}</p>
						))}
					</Panel>						
				</Collapse>}
			</Drawer>
		</>
	);
};

ErrorPool.propTypes = {
	token: PropTypes.string.isRequired,
	user: PropTypes.shape({
		email: PropTypes.string.isRequired,		
	}),
	// PropTypes for the Component InformationComponent 
	title: PropTypes.string,
	attribute: PropTypes.string,
};

export default ErrorPool;