import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;


export const train_model = async (token, company_id, position) => {
	try {
		const res = await axios.post(`${apiUrl}/ml_model_generator`, {
			token,
			company_id,
			position
		});
		return res;
	} catch (err) {
		return err;
	}
};