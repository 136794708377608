import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const get_users = async (token) => {
	try {
		const res = await axios.post(`${apiUrl}/get_users`, {
			token
		});
		return res.data;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const edit_user = async (token, data) => {
	try {
		const res = await axios.post(`${apiUrl}/edit_user`, data);
		return res;
	} catch (err) {
		console.error(err);
		throw err;
	}
};
