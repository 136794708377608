import React, { useState }  from "react";
import { 
	Select, 
	Button,
	message,
} from "antd";
import { train_model } from "../../services/classifier";
import PropTypes from "prop-types";


// Before: const Reports = ({user, token, setUser, setToken, company})
const Classifier = ({token, company}) => {

	const [position, setPosition] = useState("in");
	const [loadingTraining, setloadingTraining] = useState(false);

	const handleChangePosition = (value) => {
		setPosition(value);
	};

	const handleGenerateModel = async () => {
		if (!company.id) {
			message.error("Por favor seleccione una empresa primero");
			return;
		}
		setloadingTraining(true);
		const res = await train_model(token, company.id, position);

		if (res.status == 200) {
			setloadingTraining(false);
			message.success(res.data);
		}
		else{
			setloadingTraining(false);
			message.error(res.response.data);
		}
	};

	return (
		<>
			<Select
				defaultValue={position}
				style={{
					width: 120,
				}}
				onChange={handleChangePosition}
				options={[
					{
						value: "in",
						label: "Ventas",
					},
					{
						value: "out",
						label: "Compras",
					},
				]}
			/>

			<Button
				loading={loadingTraining}
				onClick={handleGenerateModel}
			>
            Generate Model
			</Button>
		</>
	);
};

Classifier.propTypes = {
	token: PropTypes.string.isRequired,
	company: PropTypes.shape({
		id: PropTypes.string.isRequired,
	}).isRequired,
};

export default Classifier;
