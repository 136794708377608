import React, { useState, useEffect } from "react";
import {
	QuestionCircleOutlined,
	RightOutlined,
	LeftOutlined,
	SearchOutlined
} from "@ant-design/icons";
import {
	List,
	Space,
	Skeleton,
	Button,
	Drawer,
	Select,
	message,
	Popconfirm,
	Input,
	Descriptions,
	Table,
	Tag,
	DatePicker,
	Modal,
	Switch,
	Checkbox,
	Col, 
	Row,
} from "antd";
import { get_invoices, edit_invoice, frases } from "../../services/invoices";
import ReporteExcel from "../reports/ExcludedReport";
import MassiveInvoices from "./MassiveInvoices";
import he from "he";
import dayjs from "dayjs";
import "../../hover.css";
import PropTypes from "prop-types";

const dateFormat = "YYYY/MM/DD";

const no_selected = {
	id: "",
	reference: "",
	number: "",
	type: "",
	class: "",
	date: "",
	currency: "",
	sender_id: "",
	sender_name: "",
	establishment_id: "",
	establishment_name: "",
	receptor_id: "",
	receptor_name: "",
	total: "",
	taxes: [],
	canceled: "",
	url: "",
	items: [],
	tax_authority_specifications: {
		frases: [],
	},
	registration_date_aro: "",
	accounting_specifications: {
		out: {
			state: "",
			description: "",
			diary: "",
			expense_type: "",
			accounting_date: "",
		},
	},
};

const no_selected_company = {
	id: "",
	address: "",
	country: "",
	description: "",
	is_associated_company: true,
	name: "",
	accounting_resources: {
		accounting_plan: [],
		diaries: [],
		expense_accounts: [],
		sale_accounts: [],
		others:{
			analytic_accounts:[]
		}
	},
	database_id: "",
	tax_authority_specifications: {
		pequenio_contribuyente: false,
	},
	looker_link: "",
	database: undefined,
};

const INVOICES_TYPES = {
	unverified: "Sin Verificar",
	verified: "Verificada",
	excluded: "Excluída",
	history: "Historia",
	canceled: "Anulada",
	error: "Error",
	notification_sent: "Notification Sent",
};

const rangePresets = [
	{
		label: "Last 7 Days",
		value: [dayjs().add(-7, "d"), dayjs()],
	},
	{
		label: "Last 14 Days",
		value: [dayjs().add(-14, "d"), dayjs()],
	},
	{
		label: "Last 30 Days",
		value: [dayjs().add(-30, "d"), dayjs()],
	},
	{
		label: "Last 90 Days",
		value: [dayjs().add(-90, "d"), dayjs()],
	},
];

const tagColor = {
	"RECI": "gold",
	"FACT": "magenta",
	"FESP": "blue"
};

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const position = "out";
const massive_invoices_limit = 150;

const PurchaseInvoices = ({ user, token, company }) => {
	const [loading, setLoading] = useState(false);
	const [loadingMore, setLoadingMore] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [loadingSearch, setLoadingSearch] = useState(false);

	const [isModalOpenSend, setIsModalOpenSend] = useState(false);
	const [open, setOpen] = useState(false);
	const placement = "right";

	const [pageSize, setPageSize] = useState(10);

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [selectedState, setselectedState] = useState(null);
	const [selected, setSelected] = useState(no_selected);

	const [invoices, setInvoices] = useState([]);
	const [invoicesBackup, setInvoicesBackup] = useState([]);
	const [sendWithIsr, setSendWithIsr] = useState(false);
	const [isr, setISR] = useState(false);

	const [hoveredItem, setHoveredItem] = useState(null);
	const [lastSelectedState, setLastSelectedState] = useState("");

	const [checkedList, setCheckedList] = useState([]);
	const checkAll = invoices.length === checkedList.length;
	const indeterminate = checkedList.length > 0 && checkedList.length < invoices.length;
	const [openMassiveInvoices, setOpenMassiveInvoices] = useState(false);
	
	
	/* COMPONENT VARIABLES */

	const showModalSend = () => {
		setIsModalOpenSend(true);
	};
	const handleOkSend = () => {
		setIsModalOpenSend(false);
		handleSubmit();
	};
	const handleCancelSend = () => {
		setIsModalOpenSend(false);
	};
	const showEdit = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
		setSelected(no_selected);
	};

	/* FORMATING */

	const reduceText = (text) => {
		if (text == "") {
			return "---";
		}
		if (text.length > 70) {
			return text.substring(0, 40) + "...";
		}
		return text;
	};

	const decodeText = (text) => {
		const decodedText = he.decode(text);
		return decodedText;
	};

	const formatNumber = (number, currency) => {
		const formattedNumber = new Intl.NumberFormat("en", {
			style: "currency",
			currency,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(number);

		return formattedNumber;
	};

	const formatShownDate = (dateString) => {
		const date = new Date(dateString);
		const options = {
			day: "2-digit",
			month: "short",
			year: "2-digit",
		};
		const newDate = date.toLocaleDateString("es-ES", options);
		return newDate;
	};
	const formatShownHour = (dateString) => {
		const date = new Date(dateString);
		const options = {
			hour: "2-digit",
			minute: "2-digit",
		};
		const timeString = date.toLocaleTimeString("es-ES", options);
		return timeString;
	};
    

	/* SEARCH INVOICES */

	const onRangeChange = (dates, dateStrings) => {
		if (dates) {
			setStartDate(dateStrings[0]);
			setEndDate(dateStrings[1]);
		} else {
			setStartDate(null);
			setEndDate(null);
		}
	};

	const onChangeState = (value) => {
		setselectedState(value);
	};

	const checkInitialConditions = () => {
		if (
			!company ||
			company === no_selected_company ||
			!selectedState ||
			!company.id
		) {
			message.error("No has seleccionado todos los valores");
			return false;
		}
		return true;
	};

	const formatDateSearch = (date, isEndDate) => {
		if (!date) return undefined;
		const newDate = new Date(date);
		if (isEndDate) {
			newDate.setDate(newDate.getDate() + 1);
		}
		return newDate.toISOString().split("T")[0];
	};

	const handleApiResponse = (response, stateToSearch) => {
		if (
			response === "No hay elementos" ||
			response === "No hay más elementos"
		) {
			message.info(response);
			if (response === "No hay elementos") {
				setInvoices([]);
				setInvoicesBackup([]);
			}
			return;
		}

		const uniqueNewInvoices = response.filter(
			(newInvoice) =>
				!invoicesBackup.some(
					(existingInvoice) => existingInvoice.id === newInvoice.id
				)
		);

		const newInvoices = invoicesBackup.concat(uniqueNewInvoices);

		const detectedInvoiceType =
			stateToSearch in INVOICES_TYPES
				? ` de tipo ${INVOICES_TYPES[stateToSearch]}`
				: "";
		message.success(`Se han cargado más facturas${detectedInvoiceType}`);

		setInvoices(newInvoices);
		setInvoicesBackup(newInvoices);
	};

	const fetchInvoices = async (lastInvoiceId) => {
		setLoading(true);

		const isNormalSearch = lastInvoiceId === undefined;

		if (isNormalSearch) {
			setLastSelectedState(selectedState);
		}

		try {
			// Search : Load More
			const stateToSearch = isNormalSearch ? selectedState : lastSelectedState;

			const response = await get_invoices(
				token,
				company.tid,
				stateToSearch,
				"out",
				formatDateSearch(startDate),
				formatDateSearch(endDate),
				lastInvoiceId
			);

			if (isNormalSearch) {
				setInvoices(response);
				setInvoicesBackup(response);
				const detectedInvoiceType =
					stateToSearch in INVOICES_TYPES
						? ` de tipo ${INVOICES_TYPES[stateToSearch]}`
						: "";
				message.success(`Se han cargado las facturas${detectedInvoiceType}`);
			} else {
				handleApiResponse(response, stateToSearch);
			}
		} catch (error) {
			message.error(error.response.data);
			setLastSelectedState("");
		} finally {
			setLoading(false);
			setLoadingSearch && setLoadingSearch(false);
			setLoadingMore && setLoadingMore(false);
		}
	};

	const handleSearch = async () => {
		if (!checkInitialConditions()) return;
		setLoadingSearch(true);
		setCheckedList([]);
		fetchInvoices();
	};

	const loadMore = async () => {
		if (!invoices.length) {
			message.error("No se pueden cargar más facturas");
			return;
		}
		setLoadingMore(true);
		const lastInvoiceId = invoicesBackup[invoicesBackup.length - 1].id;
		fetchInvoices(lastInvoiceId);
	};

	/* SUBMIT INVOICE */

	const onChangeSwitchISR = (checked) => {
		setSendWithIsr(checked);
	};

	const areItemsComplete = (items) => {
		return items.every(
			(item) =>
				item.accounting_specifications.final_description !== "" &&
				item.accounting_specifications.accounting_classification !== ""
		);
	};

	const isInvoiceComplete = (selected) => {
		const fields = ["diary", "description", "expense_type"];
		return fields.every(
			(field) =>
				selected.accounting_specifications.out[field] !== "" &&
				selected.accounting_specifications.out[field] !== undefined
		);
	};

	const getModifiedTaxAuthoritySpecifications = (
		selected,
		company,
		sendWithIsr
	) => {
		const updatedFrases = selected.tax_authority_specifications.frases.map(
			(frase) => ({
				...frase,
				scenario: parseInt(frase.scenario, 10),
				type: parseInt(frase.type, 10),
			})
		);

		const new_tax_authority_specifications = {
			frases: updatedFrases,
		};

		if(company.database !== undefined){
			if (!sendWithIsr) {
				new_tax_authority_specifications.frases =
					new_tax_authority_specifications.frases.filter(
						(frase) => !(frase.type === 1 && frase.scenario === 2)
					);
			}
		}

		return new_tax_authority_specifications;
	};

	const getInvoiceForSubmission = (selected, company, sendWithIsr) => {
		return {
			id: selected.id,
			items: selected.items.map((item) => ({
				accounting_specifications: {
					out: {
						final_description: item.accounting_specifications.out.final_description,
						accounting_classification: `${item.accounting_specifications.out.accounting_classification}`,
						analytic_classification: `${item.accounting_specifications.out.analytic_classification}`,
					},
				},
			})),
			tax_authority_specifications: getModifiedTaxAuthoritySpecifications(
				selected,
				company,
				sendWithIsr
			),
			accounting_specifications: {
				out: {
					description: selected.accounting_specifications.out.description,
					diary: `${selected.accounting_specifications.out.diary}`,
					expense_type: selected.accounting_specifications.out.expense_type,
					accounting_date: selected.accounting_specifications.out.accounting_date,
					extras: selected.accounting_specifications.out.extras,
					specs: selected.accounting_specifications.out.specs,
				},
			},
		};
	};

	const getDiaryInformation = () => {
		const result = company.accounting_resources.diaries.length > 0
			? company.accounting_resources.diaries.filter(
				(element) =>
					`${element.id}` === `${selected.accounting_specifications.out.diary}`											
			)
			: "";

		if(result.length === 0) return "";

		return result[0].name;
	};

	const handleSubmit = async () => {
		setLoadingSubmit(true);

		if (!areItemsComplete(selected.items) || !isInvoiceComplete(selected)) {
			setLoadingSubmit(false);
			message.error("No se llenaron todos los rubros necesarios");
			return;
		}

		try {
			const invoice = getInvoiceForSubmission(selected, company, sendWithIsr);
			const res = await edit_invoice(token, "verify", invoice, "out");

			if (res) {
				onClose();
				const inv = invoices.filter((value) => value.id !== selected.id);
				setInvoices(inv);
				setInvoicesBackup(inv);
				setLoadingSubmit(false);
				message.success(res.data);
				chargeNext();
			}
		} catch (error) {
			setInvoices(invoicesBackup);
			setLoadingSubmit(false);
			message.error(error.response.data);
		}
	};

	const handleMassiveRestoreSubmit =  async () => {
		setLoadingSubmit(true);

		try{
			const res = await edit_invoice(token, "massive_restore", { ids: checkedList }, position);
			if(res){
				message.success("Facturas incluidas de forma masiva Completado.");
				setInvoices(invoices.filter((invoice) => !checkedList.includes(invoice.id)));
				setCheckedList([]);
				setInvoicesBackup([]);
			}
		}catch(error){
			setInvoices(invoicesBackup);
			message.error(error.response.data);
		}

		setLoadingSubmit(false);
	};

	/* SELECT INVOICE */
	const setSelectedAndShowEdit = (item) => {
		if (selectedState === "unverified") {
			const updatedObject = {
				...item,
				accounting_specifications: {
					...item.accounting_specifications,
					out: {
						...item.accounting_specifications.out,
						accounting_date: item.date,
					},
				},
			};
			setSelected(updatedObject);
		} else {
			setSelected(item);
		}
		setTimeout(() => {
			showEdit();
		}, 500);
	};

	const handleCompanyInvoice = (item) => {
		if (item.receptor_id === company.tid || item.sender_id === company.tid) {
			setSelectedAndShowEdit(item);
		} else {
			message.error("Esta factura no es de la empresa seleccionada.");
		}
	};

	const chargeInvoice = (currentIndex, increment) => {
		const newIndex = currentIndex + increment;
		if (newIndex >= 0 && newIndex < invoices.length) {
			handleCompanyInvoice(invoices[newIndex]);
			return;
		}

		onClose();
		setSelected(no_selected);
	};

	const chargeNext = () => {
		const currentIndex = invoices.findIndex(
			(invoice) => invoice.id === selected.id
		);
		if (currentIndex !== -1) {
			chargeInvoice(currentIndex, 1);
		}
	};

	const chargePrevious = () => {
		const currentIndex = invoices.findIndex(
			(invoice) => invoice.id === selected.id
		);
		if (currentIndex !== -1) {
			chargeInvoice(currentIndex, -1);
		}
	};

	const handleSelectItem = async (item) => {
		handleCompanyInvoice(item);
	};

	const updateInvoiceAccountingSpecificationProperty = (property, value) => {
		setInvoices((invoices) => {
			return invoices.map((invoice) => {
				if (invoice.id === selected.id) {
					return {
						...invoice,
						accounting_specifications: {
							...invoice.accounting_specifications,
							out: {
								...invoice.accounting_specifications.out,
								[property]: value,
							},
						},
					};
				}
				return invoice;
			});
		});
		setSelected((prevSelected) => ({
			...prevSelected,
			accounting_specifications: {
				...prevSelected.accounting_specifications,
				out: {
					...prevSelected.accounting_specifications.out,
					[property]: value,
				},
			},
		}));
	};

	const onChangeDiary = (value) =>
		updateInvoiceAccountingSpecificationProperty("diary", value);

	const onChangeGeneralType = (value) =>
		updateInvoiceAccountingSpecificationProperty("expense_type", value);

	const onChangeDescription = (value) =>
		updateInvoiceAccountingSpecificationProperty("description", value);

	const onChangeDateForOut = (value) => {
		const date =
			value === null ? null : value.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
		updateInvoiceAccountingSpecificationProperty("accounting_date", date);
	};

	// Defining if invoice is ISR aplicable
	const isISR = (invoice) => {
		var has_isr = false;
		if(company.database !== undefined){
			invoice.tax_authority_specifications.frases.map((frase) => {
				if (frase.type == 1 && frase.scenario == 2) {
					has_isr = true;
				}
			});
		}
		if (selected.moneda == "GTQ" && selected.gran_total >= 2800 && has_isr) {
			return true;
		}
		if (selected.moneda == "USD" && has_isr) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		if (isISR(selected)) {
			setISR(true);
		} else {
			setISR(false);
		}
	}, [selected]);

	const editItemDescription = (value, line) => {
		setInvoices((invoices) =>
			invoices.map((invoice) =>
				invoice.id === selected.id
					? updateItemClassification(invoice, line, value, "final_description")
					: invoice
			)
		);
		setSelected((selectedInvoice) =>
			updateItemClassification(
				selectedInvoice,
				line,
				value,
				"final_description"
			)
		);
	};

	const updateItemClassification = (
		sourceInvoice,
		line,
		value,
		attribute_out
	) => {
		return {
			...sourceInvoice,
			items: sourceInvoice.items.map((item) =>
				item.line === line
					? {
						...item,
						accounting_specifications: {
							...item.accounting_specifications,
							out: {
								...item.accounting_specifications.out,
								[attribute_out]: value,
							},
						},
					}
					: item
			),
		};
	};

	const selectItemClassification = (value, line) => {
		const nomenclatura_name = company.accounting_resources.accounting_plan.filter(
			(element) => element.id === value
		);

		if (nomenclatura_name.length === 0) return;

		setInvoices((invoices) =>
			invoices.map((invoice) =>
				invoice.id === selected.id
					? updateItemClassification(
						invoice,
						line,
						nomenclatura_name[0].id,
						"accounting_classification"
					)
					: invoice 
			)
		);
		setSelected((selectedInvoice) =>
			updateItemClassification(
				selectedInvoice,
				line,
				nomenclatura_name[0].id,
				"accounting_classification"
			)
		);
	};

	const selectItemAnalyticClassification = (value, line) => {
		const analytic_account = company.accounting_resources.others.analytic_accounts.filter(
			(element) => element.id === value
		);

		if (analytic_account.length === 0) return;

		setInvoices((invoices) =>
			invoices.map((invoice) =>
				invoice.id === selected.id
					? updateItemClassification(
						invoice,
						line,
						analytic_account[0].id,
						"analytic_classification"
					)
					: invoice 
			)
		);
		setSelected((selectedInvoice) =>
			updateItemClassification(
				selectedInvoice,
				line,
				analytic_account[0].id,
				"analytic_classification"
			)
		);
	};

	/* SENDING INVOICE TO MODIFY STATE */

	const handleInvoiceStateChange = async (edit_value, state = null) => {
		setLoadingSubmit(true);

		// if state is null then put only the id invoice
		const invoice =
			state === null
				? { id: selected.id }
				: {
					id: selected.id,
					state: state,
				};

		try {
			const res = await edit_invoice(token, edit_value, invoice, "out");
			if (res) {
				const inv = invoices.filter((value) => value.id !== selected.id);
				setInvoices(inv);
				setInvoicesBackup(inv);
				setLoadingSubmit(false);
				message.success(res.data);
				chargeNext();
			}
		} catch (error) {
			setInvoices(invoicesBackup);
			setLoadingSubmit(false);
			message.error(error.response.data);
		}
	};

	const handleInvoiceExclude = () =>
		handleInvoiceStateChange("modify_state", "excluded");
	const handleInvoiceHistory = () =>
		handleInvoiceStateChange("modify_state", "history");
	const handleInvoiceInclude = () => handleInvoiceStateChange("restore");

	/* SEARCH INVOICE */

	const onChangeSearch = (value) => {
		const text = value.target.value;

		const matchesText = (str) => {
			if (typeof str === "string") {
				return str.toLowerCase().includes(text.toLowerCase());
			}
			else if (typeof str === "number") {
				return String(str).toLowerCase().includes(text.toLowerCase());
			}
			return false;
		};

		const checkObjectForMatch = (obj) => {
			for (const key in obj) {
				if (matchesText(obj[key])) {
					return true;
				}

				if (typeof obj[key] === "object" && obj[key] !== null) {
					if (checkObjectForMatch(obj[key])) {
						return true;
					}
				}
			}
			return false;
		};

		if (text === "") {
			setInvoices(invoicesBackup);
		} else {
			const resultado = invoicesBackup.filter((invoice) =>
				checkObjectForMatch(invoice)
			);
			setInvoices(resultado);
		}
	};
	
	const onChangeAllSelect = (e) => {
		const limitedInvoices = invoices.slice(0, massive_invoices_limit);
		setCheckedList(e.target.checked ? limitedInvoices.map((invoice_id) => invoice_id.id) : []);
	};

	const onChangeInvoiceSelected = (e, invoice_id) => {		
		if(checkedList.length > massive_invoices_limit && e.target.checked){ // Check the limit
			message.warning(`El límite de facturas a mandar de forma masiva es de ${massive_invoices_limit}`);
			return;
		}
		
		if(e.target.checked){ // Add Element
			setCheckedList([...checkedList, invoice_id]);
			return;
		}
		
		setCheckedList( // Remove id invoice
			checkedList.filter((checkList) => {
				return checkList !== invoice_id;
			})
		);		
	};

	function findAccountNameById(accountingPlan, accountId) {
		// Buscar el elemento en accountingPlan que tenga un id que coincida con accountId
		const matchingElement = accountingPlan.find(planItem => planItem.id.toString() === accountId.toString());
      
		// Si se encuentra un elemento coincidente, retorna su propiedad "name"
		if (matchingElement) {
			if (!matchingElement.display_name) {
				return matchingElement.name;
			}
			return matchingElement.display_name;
		} else {
			// Si no se encuentra ninguna coincidencia, retorna null o un mensaje indicativo
			return null; // o "No matching account found"
		}
	}

	/* Calculate the amount of elements every page should have */
	useEffect(() => {
		function calculateListSize() {
			const windowHeight = window.innerHeight;
			const elementHeight = 65;
			const maxListHeight = windowHeight - 470;

			const numElements = Math.floor(maxListHeight / elementHeight);
			if (numElements == 0) {
				setPageSize(1);
				return;
			}
			setPageSize(numElements);
		}

		window.addEventListener("resize", calculateListSize);

		calculateListSize();

		return () => {
			window.removeEventListener("resize", calculateListSize);
		};
	}, []);



	return (
		<>
			<Row>
				<Col
					span={4}
				>
					<Select
						placeholder="Selecciona un estado"
						optionFilterProp="children"
						onChange={onChangeState}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						style={{
							width: "90%"
						}}
						options={[
							{
								value: "unverified",
								label: "Sin Verificar",
							},
							{
								value: "notification_sent",
								label: "Notificación Enviada",
							},
							{
								value: "verified",
								label: "Verificada",
							},
							{
								value: "excluded",
								label: "Excluída",
							},
							{
								value: "history",
								label: "Historia",
							},
							{
								value: "canceled",
								label: "Anulada",
							},
							{
								value: "error",
								label: "Error",
							},
						]}
					/>
				</Col>
				<Col
					span={6}
				>
					<RangePicker
						presets={rangePresets}
						style={{
							width: "90%"
						}}
						onChange={onRangeChange}
					/>
				</Col>
				<Col
					span={2}
				>
					<Button
						style={{
							width:"90%"
						}}
						type="primary"
						loading={loadingSearch}
						onClick={() => {
							handleSearch();
						}}
					>
                        Buscar
					</Button>
				</Col>
				<Col span={2}>
					{invoices.length > 0 && (
						<ReporteExcel
							data={invoices.map((element) => ({
								serie: element.reference,
								numero: element.number,
								fecha: element.date,
								moneda: element.currency,
								nombre_emisor: element.sender_name,
								nit_emisor: element.sender_id,
								nombre_establecimiento: element.establishment_name,
								nombre_receptor: element.receptor_name,
								nit_receptor: element.receptor_id,
								gran_total: element.total,
							}))}
						></ReporteExcel>
					)}

				</Col>
				<Col
					span={7}
				>
					<Input
						placeholder="Palabra Clave"
						style={{
							width:"90%",
						}}
						onChange={onChangeSearch}
						prefix={
							<SearchOutlined />
						}
					/>
				</Col>
				{((selectedState === "unverified" || 
					selectedState === "notification_sent" || 
					selectedState === "error") && invoices.length > 0) &&
					<Col 
						span={3}
					>
						
						{selectedState === "unverified" && user.role == "admin" && 
									<Button 
										disabled={(checkedList.length < 2)} 
										type="primary"
										onClick={() => setOpenMassiveInvoices(true)}
										ghost
									>
											Verificar en Masa ({`${checkedList.length}/${invoices.length}`})
									</Button>
						}
						{(selectedState === "notification_sent" || selectedState === "error") && user.role == "admin" &&
									<Button 
										disabled={(checkedList.length < 2)} 
										type="primary"
										style={{marginTop: "5px"}}
										onClick={handleMassiveRestoreSubmit}
										loading={loadingSubmit}
										ghost
									>
											Incluír
									</Button>
						}
					</Col>
				}
			</Row>
			<Skeleton style={{marginTop:50}} avatar loading={loading}>
				<List
					header={
						<Row
							style={{
								background: "whitesmoke",
							}}
						>
							<Col
								flex="50px"
								style={{
									display:"flex",
									justifyContent:"center"
								}}
							>
								{
									user.role == "admin" &&
									<Checkbox 
										
										indeterminate={indeterminate} 
										checked={checkAll} 
										onChange={onChangeAllSelect}
									>
									</Checkbox>
								}
							</Col>
							<Col
								flex="auto"
							>
								<List.Item
									key={0}
								>
									<List.Item.Meta
										style={{
											maxWidth:150,
											textAlign:"center"
										}}
										title="Fecha"
										description="Hora"
									/>
									<List.Item.Meta
										title="Emisor"
										description="Establecimiento"
									/>
									<List.Item.Meta
										style={{
											maxWidth:200,
											marginRight:50,
											textAlign:"end"
										}} 
										title="Total"
										description="Total Impuestos"
									/>
									<List.Item.Meta
										style={{
											maxWidth:150,
											textAlign:"center"
										}}
										title="Tipo de Documento"
										description=""
									/>
									<List.Item.Meta
										style={{
											maxWidth:150,
											textAlign:"center"
										}}
										title="Serie"
										description="Número"
									/>
									<List.Item.Meta
										style={{
											maxWidth:100,
											textAlign:"center"
										}}
										title="Productos"
										description=""
									/>
								</List.Item>
							</Col>
						</Row>
					}
					pagination={{
						position: "top",
						align: "end",
						disabled: false,
						showSizeChanger: false,
						pageSize: pageSize,
					}}
					style={{
						textAlign: "start",
					}}
					loadMore={
						<div style={{ textAlign: "center" }}>
							<Button
								type="primary"
								style={{
									marginTop: "20px",
								}}
								loading={loadingMore}
								onClick={() => {
									loadMore();
								}}
							>
								Cargar más
							</Button>
						</div>
					}
					size="small"
					dataSource={invoices}
					renderItem={(item, index) => (
						<Row>
							{((selectedState === "unverified" || 
								selectedState === "notification_sent" || 
								selectedState === "error") && invoices.length > 0) &&
								<Col
									flex="50px"
									style={{
										display:"flex",
										justifyContent:"center"
									}}
								>
									{
										user.role == "admin" &&
										<Checkbox 										
											checked={((checkedList.find((checked) => checked === item.id)) === undefined) ? false : true}
											onChange={(e) => onChangeInvoiceSelected(e, item.id)} 
											style={{marginTop: "20px"}}
										/>
									}
								</Col>
							}
							<Col flex="auto">
								<List.Item
									key={index}
									onMouseEnter={() => setHoveredItem(index)}
									onMouseLeave={() => setHoveredItem(null)}
									className={hoveredItem === index ? "hovered-item" : ""}
									onClick={() => {
										handleSelectItem(item);
									}}
								>
									<List.Item.Meta
										style={{
											maxWidth:150,
											textAlign:"center"
										}}
										title={
											formatShownDate(item.date)
										}
										description={
											formatShownHour(item.date)
										}
									/>
									<List.Item.Meta
										title={
											<a
												onClick={() => {
													handleSelectItem(item);
												}}
											>
												{reduceText(decodeText(item.sender_name))}
											</a>
										}
										description={reduceText(decodeText(item.establishment_name))}
									/>
									<List.Item.Meta
										style={{
											maxWidth:200,
											marginRight:50,
											textAlign:"end"
										}}
										title=
											{formatNumber(
												item.total,
												item.currency !== "" ? item.currency : "GTQ"
											)}
										description=
											{formatNumber(
												item.taxes.reduce((acc, tax) => acc + tax.total, 0),
												item.currency !== "" ? item.currency : "GTQ"
											)}
									/>
									<List.Item.Meta
										style={{
											maxWidth:150,
											textAlign:"center"
										}}
										title={
											<Tag color={tagColor[item.type]? tagColor[item.type]: "blue"}>{item.type}</Tag>
										}
									/>
									<List.Item.Meta
										style={{
											maxWidth:150,
											textAlign:"center"
										}}
										title={
											item.reference
										}
										description={
											item.number
										}
									/>
									<List.Item.Meta
										style={{
											maxWidth:100,
											textAlign:"center"
										}}
										description={
											item.items.length
										}
									/>
								</List.Item>
							</Col>
						</Row>
					)}
				/>
			</Skeleton>
			<Skeleton avatar active loading={loading} />
			<Skeleton avatar active loading={loading} />
			<Skeleton avatar active loading={loading} />
			<Skeleton avatar active loading={loading} />
			<Drawer
				title={selected.receptor_name}
				placement={placement}
				width="100%"
				onClose={onClose}
				open={open}
				extra={
					<Space>
						{selectedState == "unverified" && (
							<>
								<Popconfirm
									title="Pasar Factura a historia"
									description="Estas seguro que quieres pasar a historia esta factura?"
									onConfirm={handleInvoiceHistory}
									icon={<QuestionCircleOutlined style={{ color: "#edc01c" }} />}
								>
									<Button
										type="primary"
										size="large"
										style={{
											width: "200px",
										}}
										loading={loadingSubmit}
									>
										Historia
									</Button>
								</Popconfirm>
								<Popconfirm
									title="Excluír Factura"
									description="Estas seguro que quieres excluír esta factura?"
									onConfirm={handleInvoiceExclude}
									icon={<QuestionCircleOutlined style={{ color: "#edc01c" }} />}
								>
									<Button
										type="primary"
										danger
										size="large"
										style={{
											width: "200px",
										}}
										loading={loadingSubmit}
									>
										Excluír
									</Button>
								</Popconfirm>
							</>
						)}
						{(selectedState === "excluded" || selectedState === "history" || selectedState === "notification_sent") && (
							<Popconfirm
								title="Incluír Factura"
								description="Estas seguro que quieres incluír esta factura?"
								onConfirm={handleInvoiceInclude}
								icon={<QuestionCircleOutlined style={{ color: "#edc01c" }} />}
							>
								<Button
									type="primary"
									size="large"
									style={{
										width: "200px",
									}}
									loading={loadingSubmit}
								>
									Incluír
								</Button>
							</Popconfirm>
						)}
						<Button
							type="primary"
							icon={<LeftOutlined />}
							size="large"
							onClick={() => {
								onClose();
								chargePrevious();
							}}
						/>
						<Button
							type="primary"
							icon={<RightOutlined />}
							size="large"
							onClick={() => {
								onClose();
								chargeNext();
							}}
						/>
					</Space>
				}
			>
				<Descriptions
					layout="horizontal"
					column={6}
					labelStyle={{
						color: "black",
						fontWeight: "bold",
					}}
				>
					<Descriptions.Item label="Serie" span={1}>
						{selected.reference}
					</Descriptions.Item>
					<Descriptions.Item label="Número" span={1}>
						{selected.number}
					</Descriptions.Item>
					<Descriptions.Item label="Tipo" span={1}>
						{selected.type}
					</Descriptions.Item>
					<Descriptions.Item label="Moneda" span={1}>
						{selected.currency}
					</Descriptions.Item>
					<Descriptions.Item label="Fecha de Emisión" span={2}>
						{formatShownDate(selected.date)}
					</Descriptions.Item>
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					<Descriptions.Item label={"Nit Emisor"} span={1}>
						{selected.sender_id}
					</Descriptions.Item>
					<Descriptions.Item label={"Nombre Emisor"} span={4}>
						{selected.sender_name}
					</Descriptions.Item>
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					{/* <Descriptions.Item label="Establecimiento Emisor" span={3}>
						{decodeText(selected.establishment_name)}
					</Descriptions.Item> */}
					<Descriptions.Item label="" span={1}>
						{isr && <div>ISR APLICABLE</div>}
					</Descriptions.Item>
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					<Descriptions.Item label={"Diario"} span={4}>
						{selectedState === "verified" &&
							(selected !== no_selected && decodeText(getDiaryInformation()))
						}
						{selectedState === "unverified" && (
							<Select
								showSearch
								status={
									selected.accounting_specifications.out.diary ? "" : "error"
								}
								placeholder="Selecciona un Diario"
								optionFilterProp="children"
								onChange={onChangeDiary}
								value={findAccountNameById(
									company.accounting_resources.diaries,
									selected.accounting_specifications.out.diary
								)
								}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								style={{
									width: "100%",
								}}
								options={
									company.accounting_resources.diaries
										? company.accounting_resources.diaries
											.filter((element) => element.type === "purchase")
											.map((element) => ({
												label: element.name,
												value: element.id,
											}))
										: []
								}
							/>
						)}
					</Descriptions.Item>
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					<Descriptions.Item label={"Descripción General"} span={4}>
						{selectedState === "verified" &&
							(selected.accounting_specifications.out.description
								? selected.accounting_specifications.out.description
								: "")}
						{selectedState === "unverified" && (
							<TextArea
								rows={3}
								showCount
								status={
									selected.accounting_specifications.out.description
										? ""
										: "error"
								}
								value={
									selected.accounting_specifications.out.description
										? selected.accounting_specifications.out.description
										: ""
								}
								style={{
									minWidth: "150px",
									marginBottom: "15px",
								}}
								placeholder="Ingresa una descripción"
								onChange={(event) => onChangeDescription(event.target.value)}
							/>
						)}
					</Descriptions.Item>
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					<Descriptions.Item label="Gran Total" span={2}>
						<h1>
							{formatNumber(
								selected.total,
								selected.currency !== "" ? selected.currency : "GTQ"
							)}
						</h1>
					</Descriptions.Item>
					{selected.taxes !== null && selected.taxes.length !== 0 && (
						<Descriptions.Item label={"Impuestos"} span={2}>
							<Table
								pagination={false}
								bordered={false}
								style={{
									width: "100%",
								}}
								columns={[
									{
										title: "Impuesto",
										dataIndex: "name",
										key: "name",
									},
									{
										title: "Precio",
										dataIndex: "total",
										key: "total",
									},
								]}
								dataSource={selected.taxes.map((item, index) => ({
									key: index,
									name: item.name,
									total: formatNumber(
										item.total,
										selected.currency !== "" ? selected.currency : "GTQ"
									),
								}))}
							/>
						</Descriptions.Item>
					)}
					{(selected.taxes === null || selected.taxes.length === 0) && (
						<Descriptions.Item label={"Impuestos"} span={2}>
							<div>Este documento no tiene impuestos</div>
						</Descriptions.Item>
					)}
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					<Descriptions.Item label="" span={1}></Descriptions.Item>

					<Descriptions.Item label="" span={2}>
						{selected.tax_authority_specifications.frases !== null &&
							selected.tax_authority_specifications.frases.length !== 0 && (
							<Table
								size="small"
								style={{
									width: "100%",
								}}
								pagination={false}
								columns={[
									{
										title: "Especificaciones",
										dataIndex: "frase",
										key: "frase",
										render: (text) => <>{text}</>,
									},
								]}
								dataSource={selected.tax_authority_specifications.frases.map(
									(frase) => ({
										frase: frases[frase.type][frase.scenario],
									})
								)}
							/>
						)}
						{selected.tax_authority_specifications.frases === null &&
							selected.tax_authority_specifications.frases.length !== 0 && (
							<div>Este documento no tiene leyendas</div>
						)}
					</Descriptions.Item>
					<Descriptions.Item label="Tipo de Gasto General" span={2}>
						{selectedState === "verified" &&
							(selected.accounting_specifications.out.expense_type
								? selected.accounting_specifications.out.expense_type
								: "")}
						{selectedState === "unverified" && (
							<Select
								showSearch
								status={
									selected.accounting_specifications.out.expense_type
										? ""
										: "error"
								}
								placeholder="Selecciona un Diario"
								optionFilterProp="children"
								onChange={onChangeGeneralType}
								value={
									selected.accounting_specifications.out.expense_type
										? selected.accounting_specifications.out.expense_type
										: ""
								}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								style={{
									width: "100%",
								}}
								options={[
									{
										value: "compra",
										label: "Compra/Bien",
									},
									{
										value: "servicio",
										label: "Servicio",
									},
									{
										value: "combustible",
										label: "Combustible",
									},
								]}
							/>
						)}
					</Descriptions.Item>
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					<Descriptions.Item label={`Fecha${(selectedState === "unverified" || selectedState === "verified") ? " Original" : ""}`} span={2}>
						<DatePicker
							picker="date"
							value={
								selected.date
									? dayjs(
										selected.date,
										dateFormat
									)
									: null
							}
							disabled
						/>
					</Descriptions.Item>					
					{(selectedState === "unverified" || selectedState === "verified") && (
						<Descriptions.Item label={"Fecha Contable"} span={2}>
							<DatePicker
								picker="date"
								status={
									selected.accounting_specifications.out.accounting_date
										? ""
										: "error"
								}
								value={
									selected.accounting_specifications.out.accounting_date
										? dayjs(
											selected.accounting_specifications.out.accounting_date,
											dateFormat
										)
										: null
								}
								onChange={onChangeDateForOut}
								disabled={
									selectedState === "verified" ||
									selectedState === "notification_sent" ||
									selectedState === "excluded" ||
									selectedState === "history" ||
									selectedState === "canceled" ||
									selectedState === "error"
								}
							/>
						</Descriptions.Item>
					)}
					{(selectedState === "excluded" || selectedState === "history" || selectedState === "canceled" || selectedState === "error" || selectedState === "notification_sent") && (
						<>
							<Descriptions.Item label="" span={1}></Descriptions.Item>
							<Descriptions.Item label="" span={1}></Descriptions.Item>						
						</>
					)}
					<Descriptions.Item label="" span={1}></Descriptions.Item>
					{selected.items && (
						<Descriptions.Item label="" span={6}>
							<Table
								size="small"
								style={{
									width: "100%",
								}}
								pagination={false}
								bordered={false}
								columns={[
									{
										title: "Cantidad",
										dataIndex: "quantity",
										key: "quantity",
										render: (text) => (
											<div style={{ width: "60px" }}>{text}</div>
										),
									},
									{
										title: "Tipo",
										dataIndex: "type",
										key: "type",
										render: (text) => (
											<div style={{ width: "30px" }}>{text}</div>
										),
									},
									{
										title: "Descripción Inicial",
										dataIndex: "initial_description",
										key: "initial_description",
									},
									{
										title: "Descripción Final",
										dataIndex: "final_description",
										key: "final_description",
										render: (value) =>
											selectedState === "unverified" ? (
												<TextArea
													rows={2}
													showCount
													value={
														selected.items.find(
															(item) => item.line === value[1]
														).accounting_specifications.out.final_description
													}
													maxLength={25}
													style={{
														minWidth: "150px",
														marginBottom: "15px",
													}}
													placeholder="Ingresa nueva descripción"
													onChange={(event) =>
														editItemDescription(event.target.value, value[1])
													}
												/>
											) : selectedState === "verified" ? (
												<div
													style={{
														minWidth: "150px",
														marginBottom: "15px",
													}}
												>
													{
														selected.items.find(
															(item) => item.line === value[1]
														).accounting_specifications.out.final_description
													}
												</div>
											) : (
												<div
													style={{
														minWidth: "150px",
														marginBottom: "15px",
													}}
												>
													{""}
												</div>
											),
									},
									{
										title: "Clasificación",
										dataIndex: "accounting_classification",
										key: "accounting_classification",
										render: (value) => (
											<Select
												showSearch
												disabled={
													selectedState === "verified" ||
													selectedState === "notification_sent" ||
													selectedState === "excluded" ||
													selectedState === "history" ||
													selectedState === "canceled" ||
													selectedState === "error"
												}
												placeholder="Clasificación"
												filterOption={(input, option) =>
													(option?.label ?? "")
														.toLowerCase()
														.includes(input.toLowerCase())
												}
												status={
													selected.items.find((item) => item.line === value[1])
														.accounting_specifications.out
														.accounting_classification
														? ""
														: "error"
												}
												value={
													findAccountNameById(
														company.accounting_resources.accounting_plan,
														selected.items.find((item) => item.line === value[1])
															.accounting_specifications.out
															.accounting_classification
													)
												}
												style={{
													minWidth: "100px",
													width: "100%",
												}}
												popupMatchSelectWidth={false}
												options={
													company.accounting_resources.accounting_plan && company.accounting_resources.expense_accounts
														? company.accounting_resources.accounting_plan
															.filter((element) =>
																company.accounting_resources.expense_accounts.includes(
																	element.id
																)
															)
															.map((element) => ({
																label: element.display_name,
																value: element.id,
															}))
														: []
												}
												onChange={(element) =>
													selectItemClassification(element, value[1])
												}
											/>
										),
									},
									{
										title: "Analítica",
										dataIndex: "analytic_classification",
										key: "analytic_classification",
										render: (value) => (
											<Select
												showSearch
												disabled={
													selectedState === "verified" ||
													selectedState === "notification_sent" ||
													selectedState === "excluded" ||
													selectedState === "history" ||
													selectedState === "canceled" ||
													selectedState === "error"
												}
												placeholder="Analíticas"
												filterOption={(input, option) =>
													(option?.label ?? "")
														.toLowerCase()
														.includes(input.toLowerCase())
												}
												status=""
												value={
													findAccountNameById(
														company.accounting_resources.others.analytic_accounts,
														selected.items.find((item) => item.line === value[1])
															.accounting_specifications.out
															.analytic_classification
													)
												}
												style={{
													minWidth: "100px",
													width: "100%",
												}}
												popupMatchSelectWidth={false}
												options={
													company.accounting_resources.others.analytic_accounts
														? company.accounting_resources.others.analytic_accounts
															.map((element) => ({
																label: element.name,
																value: element.id,
															}))
														: []
												}
												onChange={(element) =>
													selectItemAnalyticClassification(element, value[1])
												}
											/>
										),
									},
									{
										title: "Total",
										dataIndex: "total",
										key: "total",
										render: (text) => (
											<div style={{ width: "100px" }}>{text}</div>
										),
									},
									{
										title: "Impuestos",
										dataIndex: "taxes",
										key: "taxes",
										render: (taxes) => (
											<>
												{taxes.length === 0 && (
													<div>Este producto no tiene impuestos</div>
												)}
												{taxes.length !== 0 &&
													taxes.map((tax) => (
														<Tag color="blue" key={tax.name}>
															{tax.name +
																" = " +
																formatNumber(
																	tax.total,
																	selected.currency !== ""
																		? selected.currency
																		: "GTQ"
																)}
														</Tag>
													))}
											</>
										),
									},
								]}
								dataSource={selected.items.map((item, index) => ({
									key: index,
									quantity: item.quantity,
									type: item.type,
									initial_description: item.initial_description,
									final_description: [item.final_description, item.line],
									accounting_classification: [
										item.accounting_classification,
										item.line,
									],
									analytic_classification: [
										item.analytic_classification,
										item.line
									],
									total: formatNumber(
										item.total,
										selected.currency !== "" ? selected.currency : "GTQ"
									),
									taxes: item.taxes,
								}))}
							/>
						</Descriptions.Item>
					)}
					{selectedState == "unverified" && !isr && (
						<Descriptions.Item label="" span={6}>
							<Button
								type="primary"
								size="large"
								style={{
									width: "100%",
									height: "80px",
									fontSize: "40px",
									fontWeight: "bold",
								}}
								loading={loadingSubmit}
								onClick={handleSubmit}
							>
								Guardar
							</Button>
						</Descriptions.Item>
					)}
					{selectedState == "unverified" && isr && (
						<>
							<Descriptions.Item label="" span={6}>
								<Button
									type="primary"
									size="large"
									style={{
										width: "100%",
										height: "80px",
										fontSize: "40px",
										fontWeight: "bold",
									}}
									loading={loadingSubmit}
									onClick={showModalSend}
								>
									Guardar
								</Button>
								<Modal
									title="Aplica ISR"
									centered
									open={isModalOpenSend}
									onOk={handleOkSend}
									onCancel={handleCancelSend}
									width={180}
								>
									<div
										style={{
											width: "100%",
											display: "flex",
											justifyContent: "center",
										}}
									>
										<Switch
											checked={sendWithIsr}
											onChange={onChangeSwitchISR}
										/>
									</div>
								</Modal>
							</Descriptions.Item>
						</>
					)}
				</Descriptions>
			</Drawer>
			{
				user.role == "admin" &&
				<MassiveInvoices 
					{...{
						token, 
						invoices, 
						checkedList, 
						company, 
						position,
						openMassiveInvoices, 
						setOpenMassiveInvoices, 
						setInvoices, 
						invoicesBackup, 
						setInvoicesBackup,
						setCheckedList
					}}
				/>
			}
		</>
	);
};

PurchaseInvoices.propTypes = {
	user: PropTypes.shape({
		email: PropTypes.string.isRequired,
		role: PropTypes.string.isRequired,
	}),
	token: PropTypes.string.isRequired,
	company: PropTypes.shape({
		id: PropTypes.string.isRequired,
		tid: PropTypes.string.isRequired,
		database: PropTypes.string,
		accounting_resources: PropTypes.shape({
			diaries: PropTypes.array,
			accounting_plan: PropTypes.array,
			others: PropTypes.shape({
				analytic_accounts: PropTypes.array
			}),
			expense_accounts: PropTypes.array,
			sale_accounts: PropTypes.array,
		})
	})
};

export default PurchaseInvoices;
