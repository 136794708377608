import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const get_databases = async (token) => {
	try {
		const res = await axios.post(`${apiUrl}/get_databases`, {token});
		return res.data;
	} catch(err) {
		console.error(err);
		throw err;
	}
};

export const edit_database = async (token, edit_value, data) => {
	try{
		const body = {
			token,
			edit_value,
			data
		};
		const res = await axios.post(`${apiUrl}/edit_database`, body);
		return res;
	} catch(err) {
		console.error(err);
		throw err;
	}
};