import React, { useEffect, useState } from "react";
import { 
	Skeleton,
	message,
	List,
	Button,
	Drawer,
	Space,
	Popconfirm,
	Switch,
	Transfer,
	Descriptions,
	Input,
	InputNumber
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { get_databases, edit_database } from "../../services/databases";
import { get_companies } from "../../services/companies";
import "../../hover.css";
import TextArea from "antd/es/input/TextArea";
import PropTypes from "prop-types";

const empty_database = {
	description: "",
	assigned_companies: [],
	version: null, 
	id:"", 
	system: "", 
	name: "",
	active: true,
	url: ""
};

const DataBases = ({token}) => {
	const [databases, setDatabases] = useState([]);
	const [dbCompanies, setDbCompanies] = useState([]);
	const [selected, setSelected] = useState(empty_database);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [openCreate, setOpenCreate] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [hoveredItem, setHoveredItem] = useState(null);

	const check_companies_to_assign = (item_selected = null) => {
		const formated_companies = dbCompanies.map((company) => {			
			const isDisabled = (item_selected === null) ? databases.some((item) => 
				item.assigned_companies.includes(company.nit)
			) : databases.some((item) => 
				item_selected.id !== item.id && item.assigned_companies.includes(company.nit)
			);

			return {
				...company,
				disabled: isDisabled,
			};
		});
		setDbCompanies(formated_companies);
	};

	const reset_companies_to_assign = () => {
		const formated_companies = dbCompanies.map((company) => {
			return {
				...company,
				disabled: false,
			};
		});
		setDbCompanies(formated_companies);
	};
	
	const showCreate = () => {
		setOpenCreate(true);
		check_companies_to_assign();
	};
	
	const showEdit = (item) => {
		setOpenEdit(true);
		check_companies_to_assign(item);
	};

	const onClose = () => {
		setOpenEdit(false);
		setOpenCreate(false);
		reset_companies_to_assign();
		setSelected(empty_database);
	};

	const handleChageDB = (value, attribute) => {
		setSelected((dbSelected) => ({
			...dbSelected, [attribute] : value
		}));
	};

	const handleChange = (newTargetKeys) => {
		handleChageDB(newTargetKeys, "assigned_companies");
	};
	
	const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
		setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
	};
	
	const submitChanges = async (edit_value) => {
		if(edit_value === "create"){
			if(
				selected.name === "" || 
				selected.system === "" || 
				selected.version === null || 
				selected.description === "" || 
				selected.url === ""
			){
				message.warning("Porfavor llene los campos pendientes para crear la nueva base de datos");
				return;
			}

			if(selected.version <= 0){
				message.warning("Número de versión invalido");
				return;
			}
		}

		setLoading(true);
		setLoadingSubmit(true);		

		const data_content = (value) => {
			if(value === "create"){
				return {
					name: selected.name,
					system: selected.system.toLowerCase(),
					version: `${selected.version}`,
					description: selected.description,
					url: selected.url,
					assigned_companies: selected.assigned_companies,
				};
			}
			if(value === "edit"){
				return {
					id: selected.id,
					assigned_companies: selected.assigned_companies
				};					
			}
			if(value === "deactivate"){
				return {id: selected.id};
			}
			return null;
		};
		
		try {
			const refresh_databases = async (message_text) => {
				message.success(message_text);
				const reload_dbs = await get_databases(token);
				if(reload_dbs) {
					setDatabases(reload_dbs);
				}
				onClose();
			};
			
			const res = await edit_database(token, edit_value, data_content(edit_value));
			if(res){
				if(res.status === 201){
					refresh_databases(res.data);
				}
				if(res.status === 200){					
					if(res.data.state === true){
						refresh_databases(res.data.message);
					}
					else{
						message.error(res.data.message);
					}
				}
			}
		} catch(error){
			console.error(error);
			message.error(error.message);
			onClose();
		}

		setLoading(false);
		setLoadingSubmit(false);
	};
	
	const reduceText = (text) => {
		if (text == "") {
			return "---";
		}
		if (text.length > 40) {
			return text.substring(0, 40) + "...";
		}
		return text;
	};

	useEffect(() => {
		const fetchData = async () => {
			try{
				const res = await get_databases(token);
				if(res) {
					setDatabases(res);
				}

				const res_companies = await get_companies(token, "is_associated");
				if(res_companies){
					
					const formated_companies = res_companies.map((company) => {
						return {
							nit: company.id,
							name: company.name,
							key: company.id,
							disabled: false
						};
					});
					setDbCompanies(formated_companies);
				}

				setLoading(false);

				if(res.length === 0){
					message.warning("No hay bases de datos creados");
				}
			} catch(error){
				console.error(error.response.data);
				message.error(error.response.data);
			}
		};

		const calculateListSize = () => {
			const windowHeight = window.innerHeight;
			const elementHeight = 65;
			const maxListHeight = windowHeight - 350;

			const numElements = Math.max(
				1,
				Math.floor(maxListHeight / elementHeight)
			);
			setPageSize(numElements);
		};

		window.addEventListener("resize", calculateListSize);
		fetchData();
		calculateListSize();

		return () => window.removeEventListener("resize", calculateListSize);
	}, []);

	const TransferCompaniesComponent = () => {
		return (
			<div>
				<h3 style={{ color: "#808080" }}>Compañias Asignadas:</h3>
				<Transfer
					dataSource={dbCompanies}
					disabled={!selected.active}
					loading={loading}
					listStyle={{
						width: 350,
						height: 300,
					}}
					titles={["Compañias Disponibles", "Compañias Asignados"]}
					targetKeys={selected.assigned_companies}
					selectedKeys={selectedKeys}
					onChange={handleChange}
					onSelectChange={handleSelectChange}
					render={(item) => item.name}
					style={{
						marginBottom: 16,
						marginTop:"10px"
					}}
				/>
			</div>
		);
	};

	const InformationComponent = ({title, attribute}) => {
		return (
			<div>
				<h3 style={{ color: "#808080" }}>{title}</h3>
				<p>{attribute}</p>
			</div>
		);
	};

	const UrlComponent = () => {
		return (
			<div>
				<h3 style={{ color: "#808080" }}>URL: </h3>
				<a 
					href={selected.url}
					target="_blank"
					rel="noopener noreferrer"
				>
					<p className="urlText">{selected.url}</p>
				</a>
			</div>
		);
	};

	return (
		<>
			<Skeleton avatar loading={loading} active>
				<Button
					type="primary"
					style={{
						marginBottom: "20px",
						width: "100%",
						height: "10%",
						fontSize: "18px",
					}}
					onClick={showCreate}
				>
					Agregar Nueva Base de Datos
				</Button>
				<List
					pagination={{
						position:"top",
						align:"end",
						pageSize: pageSize,
						showSizeChanger: false,
						disabled: false,
					}}
					dataSource={databases}
					renderItem={(item, index) => (
						<List.Item
							key={index}
							onMouseEnter={() => setHoveredItem(index)}
							onMouseLeave={() => setHoveredItem(null)}
							className={hoveredItem === index ? "hovered-item" : ""}
							onClick={() => {
								setSelected(item);
								showEdit(item);
							}}
							actions={[<a key="edit"></a>]}
						>
							<Skeleton avatar title={false} loading={loading} active>
								<List.Item.Meta
									title={reduceText(item.name)}
									description={reduceText(`${item.system}, ${item.version}`)}
								/>
								<Switch 
									checked={!!item.active} 
									checkedChildren="Activado" 
									unCheckedChildren="Desactivado"
									Toggle disabled
								/>
							</Skeleton>
						</List.Item>
					)}
				></List>
			</Skeleton>

			<Skeleton avatar active loading={loading} />
			<Skeleton avatar active loading={loading} />
			<Skeleton avatar active loading={loading} />
			<Skeleton avatar active loading={loading} />

			<Drawer
				title="Agregar una Base de Datos"
				placement="right"
				width={800}
				onClose={onClose}
				open={openCreate}
				extra={
					<Space>
						<Button type="primary" loading={loadingSubmit} onClick={() => {submitChanges("create");}}>
							Crear
						</Button>
					</Space>
				}
			>
				<Descriptions
					layout="vertical"
					labelStyle={{
						color: "black",
						fontWeight: "bold",
					}}
				>					
					<Descriptions.Item label={"Nombre"}>
						<Input
							rows={1}
							showCount
							status={(selected.name.trim() === "") ? "error" : ""}
							value={selected.name}
							onChange={(event) => handleChageDB(event.target.value, "name")}
							maxLength={15}
						/>
					</Descriptions.Item>					
					<Descriptions.Item label={"Sistema"}>
						<Input
							rows={1}
							showCount
							status={(selected.system.trim() === "") ? "error" : ""}
							value={selected.system}
							onChange={(event) => handleChageDB(event.target.value, "system")}
							maxLength={15}
						/>
					</Descriptions.Item>
					<Descriptions.Item label={"Versión"}>
						<InputNumber
							rows={1}
							status={(selected.version === null || (selected.version !== null && selected.version <= 0)) ? "error" : ""}
							value={selected.version}
							onChange={(value) => handleChageDB(value, "version")}
						/>
					</Descriptions.Item>					
					<Descriptions.Item label={"Descripción"}>
						<TextArea
							rows={1}
							showCount
							status={(selected.description.trim() === "") ? "error" : ""}
							value={selected.description}
							onChange={(event) => handleChageDB(event.target.value, "description")}
							maxLength={30}
						/>
					</Descriptions.Item>
					<Descriptions.Item label={"URL"}>
						<TextArea
							rows={1}
							showCount
							status={(selected.url.trim() === "") ? "error" : ""}
							value={selected.url}
							onChange={(event) => handleChageDB(event.target.value, "url")}
						/>
					</Descriptions.Item>
				</Descriptions>
				<TransferCompaniesComponent/>
			</Drawer>

			<Drawer
				title="Editar Base de Datos"
				placement="right"
				width={800}
				onClose={onClose}
				open={openEdit}
				extra={
					<Space>
						<Popconfirm
							title="Desactivar Base de Datos"
							description="Estas seguro que quieres desactivar esta base de datos?"
							onConfirm={() => {submitChanges("deactivate");}}
							icon={<QuestionCircleOutlined style={{ color: "red" }} />}
						>
							<Button type="primary" disabled={!selected.active} loading={loadingSubmit} danger>
								Desactivar
							</Button>
						</Popconfirm>
						<Button type="primary" disabled={!selected.active} loading={loadingSubmit} onClick={() => {submitChanges("edit");}}>
							Editar
						</Button>
					</Space>
				}
			>				
				<InformationComponent title={"Nombre: "} attribute={selected.name} />
				<InformationComponent title={"Sistema: "} attribute={selected.system} />
				<InformationComponent title={"Versión: "} attribute={selected.version} />
				<InformationComponent title={"Descripción: "} attribute={selected.description} />				
				<UrlComponent/>
				<TransferCompaniesComponent/>
			</Drawer>
		</>
	);
};

DataBases.propTypes = {
	token: PropTypes.string.isRequired,
	// For the Component of "InformationComponent"
	title: PropTypes.string, 
	attribute: PropTypes.string,
};

export default DataBases;