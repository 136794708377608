import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const get_companies = async (token, type_search, id="") => {
	let body = {
		token,
		type_search
	};
	if (type_search == "specific" && id != "") {
		body.id = id;
	}

	try {
		const res = await axios.post(`${apiUrl}/get_companies`, body);
		return res.data;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const reload_nomenclature = async (token, company_id) => {
	// eslint-disable-next-line no-useless-catch
	try {
		const body = {
			token,
			company_id
		};
		const res = await axios.post(`${apiUrl}/get_nomenclature_notificator`, body);
		return res;
	} catch (err) {
		throw err;
	}
};

export const edit_company = async (token, data) => {
	try {
		const res = await axios.post(`${apiUrl}/edit_company`, {
			token,
			edit_value: "edit",
			data
		});
		return res;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const create_company = async (token, data) => {
	try {
		const res = await axios.post(`${apiUrl}/edit_company`, {
			token,
			edit_value: "create",
			data
		});
		return res;
	} catch (err) {
		console.error(err);
		throw err;
	}
};
