import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const get_errors_of_error_pool = async (token) => {
	try {
		const res = await axios.post(`${apiUrl}/get_errors_of_error_pool`, {token});
		return res.data;
	} catch(err) {
		console.error(err);
		throw err;
	}
};

export const edit_error_in_error_pool = async (token, error_id, user) => {
	try {
		const res = await axios.post(`${apiUrl}/edit_error_in_error_pool`, {
			token,
			error_id,
			user
		});
		return res;
	} catch(err) {
		console.error(err);
		throw err;
	}
};