import React, { useState } from "react";

import {
	Button,
	Drawer,
	Select,
	Descriptions,
	Tag,
	Row,
	Input,
	message,
	Switch
} from "antd";
import { edit_invoice } from "../../services/invoices";
import PropTypes from "prop-types";

const { CheckableTag } = Tag;

const no_selected_massive_invoice = {
	diary: undefined,
	general_expense: "",
	classification: undefined,
	description: "",
	analytic_classification: "",
};

const { TextArea } = Input;

const MassiveInvoices = ({
	token, 
	invoices, 
	checkedList, 
	company, 
	position, 
	openMassiveInvoices, 
	setOpenMassiveInvoices, 
	setInvoices, 
	invoicesBackup, 
	setInvoicesBackup, 
	setCheckedList
}) => {
	const keysToExclude = ["description", "frases", "state", "registration_date_aro", "taxes", "canceled", "specs", position === "out" ? "in" : "out"];
	const [invoiceMassive, setInvoiceMasive] = useState(no_selected_massive_invoice);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [selectedTags, setSelectedTags] = useState([]);
	const [overrideClassification, setOverrideClassification] = useState(true);
	
	const handleMassiveSubmit = async () => {
		if(position !== "out" && position !== "in"){
			message.error("Invalid position in Massive Component");
			return;
		}

		if(invoiceMassive.diary === undefined || invoiceMassive.general_expense.trim() === "" || invoiceMassive.classification === undefined){
			message.warning("Porfavor llena los campos pendientes");
			return;
		}	
		
		setLoadingSubmit(true);

		try{			
			const data = {
				ids: checkedList,
				diary: `${invoiceMassive.diary}`,
				general_expense: invoiceMassive.general_expense,
				classification: `${invoiceMassive.classification}`,
				description: invoiceMassive.description,
				analytic_classification: `${invoiceMassive.analytic_classification}`,
				override_classification: overrideClassification
			};
			
			const res = await edit_invoice(token, "massive_verify", data, position);
			if(res){
				message.success("Facturas analizadas de forma masiva Completado.");
				onClose();
				setInvoices(invoices.filter((invoice) => !checkedList.includes(invoice.id)));
				setCheckedList([]);				
				setInvoicesBackup([]);
			}
		}catch(error){
			setInvoices(invoicesBackup);
			message.error(error.response.data);
		}

		setLoadingSubmit(false);
	};

	const onClose = () => {
		setInvoiceMasive(no_selected_massive_invoice);
		setOpenMassiveInvoices(false);
	};

	const handleChange = (tag, checked) => {
		const nextSelectedTags = checked
			? [...selectedTags, tag]
			: selectedTags.filter((t) => t !== tag);
		setSelectedTags(nextSelectedTags);
		if (checked) {
			invoiceMassive.description = invoiceMassive.description + "{" + tag + "}";
		}
	};
	
	const filterInvoicesByID = (invoices, selectedIDs) => {
		if (!invoices || !selectedIDs) return [];
		return invoices.filter(invoice => selectedIDs.includes(invoice.id));
	};
	
	const findCommonKeys = (invoices, excludeKeys) => {
		if (!invoices.length) return [];
		let listOfKeys = invoices.map(invoice => getNestedKeys(invoice, "", excludeKeys));
		let commonKeys = listOfKeys.shift().filter(key => 
			listOfKeys.every(keys => 
				keys.some(k => k.path === key.path)
			)
		);
		return commonKeys;
	};

	const getNestedKeys = (obj, path, excludeKeys) => {
		if (!excludeKeys) excludeKeys = [];
		let keys = [];
		for (let key in obj) {
			if (excludeKeys.includes(key)) continue;
	
			if (typeof obj[key] === "object" && obj[key] !== null) {
				keys = keys.concat(getNestedKeys(obj[key], path ? `${path}.${key}` : key, excludeKeys));
			} else {
				keys.push({ path: path ? `${path}.${key}` : key, key_name: key });
			}
		}
		return keys;
	};

	function filterDiaries(company, position) {
		return company.accounting_resources.diaries
			? company.accounting_resources.diaries
				.filter((diary) => {
					// Verifica si todos los invoices son de tipo "FESP"
					// const allInvoicesAreFESP = invoices.every(invoice => invoice.type === "FESP");
		
					// Determina el tipo de diario esperado basado en la posición
					const expectedDiaryType = position === "in" ? "sale" : "purchase";
			
					// De lo contrario, se filtra por el tipo esperado basado en la posición
					return diary.type === expectedDiaryType;
				})
				.map((diary) => ({
					label: diary.name,
					value: diary.id,
				}))
			: [];
	}

	function filterAccountingPlan(company, position) {
		return company.accounting_resources.accounting_plan.filter((element) => {
			// Verifica si algún invoice es de tipo "FESP"
			// const anyInvoiceIsFESP = invoices.every(invoice => invoice.type === "FESP");

			// Cuando position es "in" o cualquier invoice es "FESP", se filtran los sale_accounts
			if (position === "in") {
				return company.accounting_resources.sale_accounts.includes(element.id);
			} 
			// Cuando position es "out", se filtran los expense_accounts
			else if (position === "out") {
				return company.accounting_resources.expense_accounts.includes(element.id);
			}
			return false;
		}).map((element) => ({
			label: element.display_name,
			value: element.id
		}));
	}

	return (
		<Drawer
			title="Verificación de Facturas en Masa"
			placement="right"
			width="100%"
			open={openMassiveInvoices}
			onClose={() => setOpenMassiveInvoices(false)}
		>
			<Descriptions
				layout="vertical"
				column={1}
				labelStyle={{
					color: "black",
					fontWeight: "bold",
				}}
			>
				<Descriptions.Item label={"Diario"}>
					<Select
						showSearch
						status={invoiceMassive.diary ? "" : "error"}
						placeholder="Selecciona un Diario"
						optionFilterProp="children"
						onChange={(value) => setInvoiceMasive({...invoiceMassive, "diary": value})}
						value={invoiceMassive.diary}
						filterOption={(input, option) =>
							(option?.label ?? "")
								.toLowerCase()
								.includes(input.toLowerCase())
						}
						style={{
							width: "100%",
						}}
						options={
							filterDiaries(company, position)
						}
					>
					</Select>
				</Descriptions.Item>
				<Descriptions.Item label={"Tipo de Gasto General"}>
					<Select
						showSearch
						status={invoiceMassive.general_expense ? "" : "error"}
						placeholder="Selecciona un Diario"
						optionFilterProp="children"
						onChange={(value) => setInvoiceMasive({...invoiceMassive, "general_expense": value})}
						value={invoiceMassive.general_expense}
						filterOption={(input, option) =>
							(option?.label ?? "")
								.toLowerCase()
								.includes(input.toLowerCase())
						}
						style={{
							width: "100%",
						}}
						options={[
							{
								value: "compra",
								label: "Compra/Bien",
							},
							{
								value: "servicio",
								label: "Servicio",
							},
							{
								value: "combustible",
								label: "Combustible",
							},
						]}
					/>
				</Descriptions.Item>
				<Descriptions.Item label={"Clasificación"}>
					<Select
						showSearch
						placeholder="Selecciona una clasificación"
						filterOption={(input, option) =>
							(option?.label ?? "")
								.toLowerCase()
								.includes(input.toLowerCase())
						}
						status={invoiceMassive.classification ? "" : "error"}
						value={invoiceMassive.classification}
						style={{
							minWidth: "100px",
							width: "100%",
						}}
						popupMatchSelectWidth={false}
						options={
							filterAccountingPlan(company, position)
						}
						onChange={(value) => setInvoiceMasive({...invoiceMassive, "classification": value})}
					/>
				</Descriptions.Item>
				<Descriptions.Item label={"Clasificación Analítica"}>
					<Select
						showSearch
						placeholder="Selecciona una clasificación"
						filterOption={(input, option) =>
							(option?.label ?? "")
								.toLowerCase()
								.includes(input.toLowerCase())
						}
						status=""
						value={invoiceMassive.analytic_classification}
						style={{
							minWidth: "100px",
							width: "100%",
						}}
						popupMatchSelectWidth={false}
						options={
							company.accounting_resources.others.analytic_accounts.map(
								(element) => ({ label: element.name, value: element.id })
							)
						}
						onChange={(value) => setInvoiceMasive({...invoiceMassive, "analytic_classification": value})}
					/>
				</Descriptions.Item>
				<Descriptions.Item label={"Datos que se puede incluir en la descripción"}>						
					<Row>
						{invoices && checkedList && findCommonKeys(filterInvoicesByID(invoices, checkedList), keysToExclude)
							.map((key, index) => (
								<CheckableTag
									key={index}
									checked={selectedTags.includes(key.path)}
									onChange={(checked) => handleChange(key.path, checked)}
								>
									{key.key_name}
								</CheckableTag>
							))
						}
					</Row>
				</Descriptions.Item>													
				<Descriptions.Item label={"Sobre escribir las clasificaciones"}>						
					<Switch defaultChecked onChange={() => {setOverrideClassification(!overrideClassification); console.log(overrideClassification);}} />
				</Descriptions.Item>													
				<Descriptions.Item label={"Descripción"}>
					<TextArea
						showCount
						maxLength={100}
						value={invoiceMassive.description}							
						onChange={(e) => setInvoiceMasive({...invoiceMassive, "description": e.target.value})}
						placeholder="Ingresa descripción junto a sus valoers"
						style={{ height: 120, resize: "none" }}
					/>
				</Descriptions.Item>		
				<Descriptions.Item label="" span={6}>
					<Button
						type="primary"
						size="large"
						style={{
							width: "100%",
							height: "80px",
							fontSize: "25px",
							fontWeight: "bold",
						}}
						loading={loadingSubmit}
						onClick={handleMassiveSubmit}
					>
						Enviar facturas de forma masiva
					</Button>
				</Descriptions.Item>
			</Descriptions>
		</Drawer>
	);
};

MassiveInvoices.propTypes = {
	token: PropTypes.string.isRequired,
	invoices: PropTypes.array.isRequired,
	checkedList: PropTypes.array.isRequired,
	company: PropTypes.shape({
		id: PropTypes.string.isRequired,
		tid: PropTypes.string.isRequired,
		database: PropTypes.string,
		accounting_resources: PropTypes.shape({
			diaries: PropTypes.array,
			accounting_plan: PropTypes.array,
			others: PropTypes.shape({
				analytic_accounts: PropTypes.array
			}),
			expense_accounts: PropTypes.array,
			sale_accounts: PropTypes.array,
		})
	}),
	position: PropTypes.string.isRequired,
	openMassiveInvoices: PropTypes.func.isRequired,
	setOpenMassiveInvoices: PropTypes.func.isRequired,
	setInvoices: PropTypes.func.isRequired,
	invoicesBackup: PropTypes.array.isRequired,
	setInvoicesBackup: PropTypes.func.isRequired,
	setCheckedList: PropTypes.func.isRequired,
};

export default MassiveInvoices;